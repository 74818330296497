// Functions
// Globals
import enums from '../enums.js'
import getPermissionFromRoute from '../functions/getPermissionFromRoute.js'
import notify from '../functions/notify.js'
import token from '../functions/token.js'
import i18n from '../i18n.js'

const t = i18n.global.t

/**
 * vue-router beforeEach hook
 * @type {import("vue-router").NavigationGuard}
 */
export default async function (to, from) {
  const loginToken = token.load()

  // Redirect to dashboard if user is logged in and tries to access the login page.
  if (loginToken && to.name === 'login') {
    return { name: 'dashboard', replace: true }
  }

  // Allow access of public routes to everyone.
  if (to.meta.public) {
    return true
  }

  // Redirect to login if user isn’t logged in and tries to access non-public routes.
  if (!loginToken && to.name !== 'login') {
    return {
      name: 'login',
      replace: true,
      query: {
        next: to.name === 'logout' ? undefined : to.path
      }
    }
  }

  // Get and check permission for current route.
  if (!getPermissionFromRoute(to)) {
    notify({
      title: t('globals.errors.noPermissionTitle'),
      content: t('globals.errors.noPermissionContent'),
      type: enums.notification.ERROR,
      timeout: 0
    })

    // Cancel navigation if the user came from another route.
    if (from.name) {
      return false
    }

    // Fall back to dashboard if the user tried to access a route directly.
    return { name: 'dashboard' }
  }

  // If everything above goes right, the user is finally allowed to visit this route.
  return true
}
