import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get settings
 */
export async function getPublic () {
  const response = await connector.get('settings').json()
  store.commit('settings/public', response)
  return response
}

/**
 * Get booked modules
 */
export async function getBookedModules () {
  const response = await connector.get('settings/booked_modules').json()
  store.commit('settings/bookedModules', response)
  return response
}

/**
 * Update settings
 * @param {Object} data Settings data object
 */
export async function updatePublic (data) {
  const response = await connector.patch('settings', data).json()
  store.commit('settings/public', response)
  return response
}
