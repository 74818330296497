// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'printpublisher',
    component: () => import('../../views/VPrintPublisherIntro.vue')
  },
  {
    path: 'gestaltung',
    name: 'printpublisher-design',
    component: () => import('../../views/VPrintPublisherDesign.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'change_configuration'),
      title: 'Gestaltung'
    }
  },
  {
    path: 'formate',
    component: () => import('../../views/VPrintPublisherTemplate.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'view_template'),
      title: 'Formate',
      sidebar: {
        content: import('../../components/CPrintPublisherSidebarTemplate.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'printpublisher-template',
        component: () => import('../../views/VPrintPublisherTemplateIntro.vue')
      },
      {
        path: 'neu',
        name: 'printpublisher-template-create',
        component: () => import('../../views/VPrintPublisherTemplateForm.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'add_template'),
          title: 'Neues Format'
        }
      },
      {
        path: ':id',
        name: 'printpublisher-template-edit',
        component: () => import('../../views/VPrintPublisherTemplateForm.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Format bearbeiten'
        }
      }
    ]
  },
  {
    path: 'referenz',
    component: () => import('../../views/VPrintPublisherDocs.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'view_template'),
      title: 'Referenz',
      sidebar: {
        content: import('../../components/CPrintPublisherSidebarDocs.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'printpublisher-docs',
        component: () => import('../../views/VPrintPublisherDocsIntro.vue')
      },
      {
        path: ':type/:path',
        name: 'printpublisher-docs-file',
        component: () => import('../../views/VPrintPublisherDocsFile.vue'),
        props: route => ({
          type: route.params.type,
          path: route.params.path
        })
      }
    ]
  },
  {
    path: 'veroeffentlichen',
    component: () => import('../../views/VPrintPublisherPublish.vue'),
    meta: {
      title: 'Veröffentlichen',
      sidebar: {
        content: import('../../components/CPrintPublisherSidebarPublish.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'printpublisher-publish',
        component: () => import('../../views/VPrintPublisherPublishIntro.vue')
      },
      {
        path: 'print',
        name: 'printpublisher-publish-print',
        component: () => import('../../views/VPrintPublisherPublishPrint.vue'),
        meta: {
          title: 'Für Print veröffentlichen'
        }
      },
      {
        path: 'web',
        name: 'printpublisher-publish-web',
        component: () => import('../../views/VPrintPublisherPublishWeb.vue'),
        meta: {
          title: 'Für Web veröffentlichen'
        }
      }
    ]
  },
  {
    path: 'inhalte',
    component: () => import('../../views/VPrintPublisherEdit.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'view_chapter'),
      title: 'Inhalte',
      sidebar: {
        content: import('../../components/CPrintPublisherSidebarEdit.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'printpublisher-edit',
        component: () => import('../../views/VPrintPublisherEditIntro.vue')
      },
      {
        path: 'kapitel/neu',
        name: 'printpublisher-edit-chapter-create',
        component: () => import('../../views/VPrintPublisherEditChapter.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'add_chapter'),
          title: 'Neues Kapitel'
        }
      },
      {
        path: 'analysenverzeichnis/neu',
        name: 'printpublisher-edit-chapter-create-analysis-directory',
        component: () => import('../../views/VPrintPublisherEditChapter.vue'),
        props: {
          createAnalysisDirectoryChapter: true
        },
        meta: {
          permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'add_chapter'),
          title: 'Neues Analysenverzeichnis'
        }
      },
      {
        path: 'kapitel/:id',
        name: 'printpublisher-edit-chapter-edit',
        component: () => import('../../views/VPrintPublisherEditChapter.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Kapitel bearbeiten'
        }
      },
      {
        path: 'abschnitt/neu/:chapterID',
        name: 'printpublisher-edit-paragraph-create',
        component: () => import('../../views/VPrintPublisherEditParagraph.vue'),
        props: route => ({
          chapterID: parseInt(route.params.chapterID)
        }),
        meta: {
          permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'add_paragraph'),
          title: 'Neuer Abschnitt'
        }
      },
      {
        path: 'abschnitt/:id',
        name: 'printpublisher-edit-paragraph-edit',
        component: () => import('../../views/VPrintPublisherEditParagraph.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Abschnitt bearbeiten'
        }
      }
    ]
  },
  {
    path: ':pathMatch(.*)',
    redirect: { name: 'printpublisher' }
  }
]
