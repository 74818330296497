import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get single paragraph
 * @param {number} id Paragraph ID
 */
export async function get (id) {
  const response = await connector.get(`printpublisher/paragraphs/${id}`).json()
  store.commit('printPublisher/addParagraph', response)
  return response
}

/**
 * Create new paragraph
 * @param {Object} data Paragraph data object
 */
export async function create (data) {
  const response = await connector.post('printpublisher/paragraphs', data).json()
  store.commit('printPublisher/addParagraph', response)
  return response
}

/**
 * Update existing paragraph
 * @param {number} id Paragraph ID
 * @param {Object} data Paragraph data object
 */
export async function update (id, data) {
  const response = await connector.patch(`printpublisher/paragraphs/${id}`, data).json()
  store.commit('printPublisher/addParagraph', response)
  return response
}

/**
 * Remove paragraph
 * @param {Object} options
 * @param {number} options.id Paragraph ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`printpublisher/paragraphs/${id}`)
  store.commit('printPublisher/removeParagraph', id)
  return response
}
