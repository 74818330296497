/**
 * Define scroll behavior after route change
 * @type {import('vue-router').RouterScrollBehavior}
 */
export default function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    const hashElement = document.querySelector(to.hash)

    let scrollMarginTop = 0

    if (hashElement) {
      scrollMarginTop = parseInt(getComputedStyle(hashElement).scrollMarginTop) + 15
    }

    return { el: hashElement, top: scrollMarginTop }
  }

  return { left: 0, top: 0 }
}
