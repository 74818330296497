import ky from 'ky'

const connector = ky.create({
  prefixUrl: '/static/printpublisher/templates'
})

/**
 * Get docs file
 * @param {string} path File path
 */
export function get (path) {
  return connector.get(path).text()
}
