import store from '../../../store'
import connector from '../../connector.js'

/**
 * Create new variant
 * @param {import('../../../types').ShopVariant} data Variant data
 * @returns {Promise<import('../../../types').ShopVariant>}
 */
export async function create (data) {
  const response = await connector.post('laborshop/variants', data).json()
  store.commit('laborShop/addVariant', response)
  return response
}

/**
 * Update existing variant
 * @param {number} id Variant ID
 * @param {import('../../../types').ShopVariant} data Variant data
 * @returns {Promise<import('../../../types').ShopVariant>}
 */
export async function update (id, data) {
  const response = await connector.patch(`laborshop/variants/${id}`, data).json()
  return response
}

/**
 * Remove variant
 * @param {Object} options
 * @param {number} options.id Variant ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`laborshop/variants/${id}`)
  store.commit('laborShop/removeVariant', id)
  return response
}
