import { LABOREDITOR_LIST_COUNT } from '../../../settings.js'
import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get analysis list
 * @param {Object} options Options
 * @param {number} options.offset Offset
 * @param {string} options.search Search term
 * @param {import('../../../types').AnalysisPubState} options.pubstate Publication state
 * @param {boolean} options.internal Internal analyses
 * @returns {Promise<import('../../../types').EditorList>}
 */
export async function get ({ offset, search, pubstate, category, internal }) {
  const response = await connector.get('laboreditor/list', {
    searchParams: {
      count: LABOREDITOR_LIST_COUNT,
      offset,
      search,
      pubstate,
      category,
      internal: internal || ''
    }
  }).json()

  store.commit('laborEditor/list', response)
  return response
}
