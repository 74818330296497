// Functions
import sortNumerally from '../../functions/sortNumerally.js'

export const namespaced = true

export const state = {
  config: {},
  templates: {},
  templateTypes: {},
  layouts: [],
  chapters: {},
  paragraphs: {},
  categories: []
}

/**
 * @type {import("vuex").GetterTree<state>}
 */
export const getters = {
  /**
   * Order chapter list
   * @returns {Array} Ordered list
   */
  orderedChapters (state) {
    const list = []

    for (const id in state.chapters) {
      list.push(state.chapters[id])
    }

    return list.sort((a, b) => sortNumerally(a, b, 'order'))
  },

  /**
   * Order paragraph list
   */
  orderedParagraphs (state) {
    /**
     * @param {number}
     */
    return function (chapterID) {
      const list = []

      for (const id in state.paragraphs) {
        if (state.paragraphs[id].parent_chapter === chapterID) {
          list.push(state.paragraphs[id])
        }
      }

      return list.sort((a, b) => sortNumerally(a, b, 'order'))
    }
  }
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Reset state
   */
  reset (state) {
    state.config = {}
    state.templates = {}
    state.templateTypes = {}
    state.layouts = []
    state.chapters = {}
    state.paragraphs = {}
    state.categories = []
  },

  /**
   * Update config
   * @param {Object} config Config data
   */
  updateConfig (state, config) {
    for (const item in config) {
      state.config[item] = config[item]
    }
  },

  /**
   * Create templates and types object
   * @param {Object} data Data
   * @param {Array} data.templates Template list
   * @param {Object} data.choices Template types
   */
  saveTemplates (state, { templates, choices }) {
    // Clear all templates in case the user deleted some
    state.templates = {}
    state.templateTypes = choices

    templates.forEach(template => {
      state.templates[template.id] = template
    })
  },

  /**
   * Create template layouts object
   * @param {Array} layouts Layout list
   */
  saveLayouts (state, layouts) {
    state.layouts = layouts
  },

  /**
   * Add or update template
   * @param {Object} template Template data
   */
  addTemplate (state, template) {
    state.templates[template.id] = template
  },

  /**
   * Remove template
   * @param {Number} id Template ID
   */
  removeTemplate (state, id) {
    delete state.templates[id]
  },

  /**
   * Create chapter and paragraph object from chapter list with IDs as keys
   * @param {Array} chapters Chapter array
   */
  saveList (state, chapters) {
    // Clear all chapters and paragraphs in case the user deleted some
    state.chapters = {}
    state.paragraphs = {}

    chapters.forEach(chapter => {
      state.chapters[chapter.id] = chapter

      chapter.paragraphs.forEach(paragraph => {
        state.paragraphs[paragraph.id] = paragraph
      })
    })
  },

  saveCategories (state, categories) {
    state.categories = categories
  },

  /**
   * Insert new chapter with ID as key
   * @param {Object} chapter Chapter data
   */
  addChapter (state, chapter) {
    state.chapters[chapter.id] = chapter
  },

  /**
   * Insert new paragraph with ID as key
   * @param {Object} paragraph Paragraph data
   */
  addParagraph (state, paragraph) {
    state.paragraphs[paragraph.id] = paragraph
  },

  /**
   * Remove chapter
   * @param {Number} id Chapter ID
   */
  removeChapter (state, id) {
    delete state.chapters[id]
  },

  /**
   * Remove paragraph
   * @param {Number} id Paragraph ID
   */
  removeParagraph (state, id) {
    delete state.paragraphs[id]
  }
}
