import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get single chapter
 * @param {number} id Chapter ID
 */
export async function get (id) {
  const response = await connector.get(`printpublisher/chapters/${id}`).json()
  store.commit('printPublisher/addChapter', response)
  return response
}

/**
 * Get chapter array
 */
export async function getList () {
  const response = await connector.get('printpublisher/chapters').json()
  store.commit('printPublisher/saveList', response)
  return response
}

/**
 * Create new chapter
 * @param {Object} data Chapter data object
 */
export async function create (data) {
  const response = await connector.post('printpublisher/chapters', data).json()
  store.commit('printPublisher/addChapter', response)
  return response
}

/**
 * Update existing chapter
 * @param {number} id Chapter ID
 * @param {Object} data Chapter data object
 */
export async function update (id, data) {
  const response = await connector.patch(`printpublisher/chapters/${id}`, data).json()
  store.commit('printPublisher/addChapter', response)
  return response
}

/**
 * Remove chapter
 * @param {Object} options
 * @param {number} options.id Chapter ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`printpublisher/chapters/${id}`)
  store.commit('printPublisher/removeChapter', id)
  return response
}
