/**
 * To save on bundle size, loading time, and CPU and RAM usage, all routes
 * should be dynamically imported.
 *
 * Each route definition follows this schema:
 *
 * {
 *   path: '/path/to/route',
 *   name?: 'route-name',
 *   component: () => import(/'../path/to/component.vue'),
 *   meta?: {
 *     public?: true|false,
 *     permission?: () => true|false,
 *     title?: 'Browser Title',
 *     sidebar?: {
 *       menu?: () => import(/'../path/to/component.vue'),
 *       content?: () => import(/'../path/to/component.vue')
 *     }
 *   },
 *   children?: childRoutes
 * }
 */

// Layouts
// Globals
import enums from '../../enums.js'
// Functions
import userCan from '../../functions/userCan.js'
import LMain from '../../layouts/LMain.vue'
// Child routes
import laborEditor from './laborEditor.js'
import laborShop from './laborShop.js'
import login from './login.js'
import printPublisher from './printPublisher.js'
import proficiency from './proficiency.js'
import settings from './settings.js'
import webPublisher from './webPublisher.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  // Authentication

  {
    path: '/login',
    component: () => import('../../layouts/LLogin.vue'),
    meta: {
      public: true
    },
    children: login
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../../views/VLogout.vue')
  },

  {
    path: '/',
    component: LMain,
    redirect: { name: 'dashboard' },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'Dashboard'
        },
        component: () => import('../../views/VDashboard.vue')
      },
      {
        path: '/news',
        name: 'news',
        meta: {
          title: 'News'
        },
        component: () => import('../../views/VNews.vue')
      },
      {
        path: '/hilfe',
        name: 'support',
        meta: {
          title: 'Hilfe'
        },
        component: () => import('../../views/VSupport.vue')
      },

      // LaborEditor

      {
        path: 'laboreditor/info',
        name: 'laboreditor-info',
        meta: {
          title: 'LaborEditor'
        },
        component: () => import('../../views/VLaborEditorInfo.vue')
      },
      {
        path: 'laboreditor',
        component: () => import('../../layouts/LLaborEditor.vue'),
        meta: {
          permission: () => userCan(enums.permissions.LABOREDITOR, 'view_laboreditorconfiguration'),
          title: 'LaborEditor',
          sidebar: {
            menu: import('../../components/CLaborEditorSidebarMenu.vue')
          }
        },
        children: laborEditor
      },

      // WebPublisher

      {
        path: 'webpublisher/info',
        name: 'webpublisher-info',
        meta: {
          title: 'WebPublisher'
        },
        component: () => import('../../views/VWebPublisherInfo.vue')
      },
      {
        path: 'webpublisher',
        component: () => import('../../layouts/LWebPublisher.vue'),
        meta: {
          permission: () => userCan(enums.permissions.WEBPUBLISHER, 'view_configurationmodel'),
          title: 'WebPublisher',
          sidebar: {
            menu: import('../../components/CWebPublisherSidebarMenu.vue')
          }
        },
        children: webPublisher
      },

      // PrintPublisher

      {
        path: 'printpublisher/info',
        name: 'printpublisher-info',
        meta: {
          title: 'PrintPublisher'
        },
        component: () => import('../../views/VPrintPublisherInfo.vue')
      },
      {
        path: 'printpublisher',
        component: () => import('../../layouts/LPrintPublisher.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PRINTPUBLISHER, 'view_configuration'),
          title: 'PrintPublisher',
          sidebar: {
            menu: import('../../components/CPrintPublisherSidebarMenu.vue')
          }
        },
        children: printPublisher
      },

      // LaborShop

      {
        path: 'laborshop/info',
        name: 'laborshop-info',
        meta: {
          title: 'LaborShop'
        },
        component: () => import('../../views/VLaborShopInfo.vue')
      },
      {
        path: 'laborshop',
        component: () => import('../../layouts/LLaborShop.vue'),
        meta: {
          permission: () => userCan(enums.permissions.LABORSHOP, 'view_configuration'),
          title: 'LaborShop',
          sidebar: {
            menu: import('../../components/CLaborShopSidebarMenu.vue')
          }
        },
        children: laborShop
      },

      // Ringversuche

      {
        path: 'ringversuche/info',
        name: 'proficiency-info',
        meta: {
          title: 'Ringversuche'
        },
        component: () => import('../../views/VProficiencyInfo.vue')
      },
      {
        path: 'ringversuche',
        component: () => import('../../layouts/LProficiency.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'view_configuration'),
          title: 'Ringversuche',
          sidebar: {
            menu: import('../../components/CProficiencySidebarMenu.vue')
          }
        },
        children: proficiency
      },

      // Settings

      {
        path: 'einstellungen',
        component: () => import('../../layouts/LSettings.vue'),
        meta: {
          title: 'Einstellungen',
          sidebar: {
            menu: import('../../components/CSettingsSidebarMenu.vue')
          }
        },
        children: settings
      }
    ]
  },

  {
    path: '/:pathMatch(.*)',
    redirect: { name: 'dashboard' }
  }
]
