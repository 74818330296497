import * as analysis from './analysis.js'
import * as maintenance from './maintenance.js'
import * as news from './news.js'
import * as version from './version.js'

export default {
  analysis,
  maintenance,
  news,
  version
}
