// Functions
import api from '../../api'
// Globals
import enums from '../../enums.js'
import notify from '../../functions/notify.js'
import i18n from '../../i18n.js'
import router from '../../router'

const t = i18n.global.t
export const namespaced = true

export const state = {
  tasks: {
    printPublisherGenerate: enums.status.IDLE
  }
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Save PrintPublisher generate task
   * @param {String} id Task ID
   */
  printPublisherGenerate (state, status) {
    state.tasks.printPublisherGenerate = status

    const contents = {
      timeout: 0,
      buttons: [
        {
          text: t('globals.show'),
          action: () => {
            const name = 'printpublisher-publish-print'

            if (router.currentRoute.name !== name) {
              router.push({ name })
            }
          }
        }
      ]
    }

    if (status === enums.status.SUCCESS) {
      contents.title = t('printpublisher.publish.print.steps.generate.generationSuccessful')
      contents.type = enums.notification.SUCCESS
      notify(contents)
    } else if (status === enums.status.ERROR) {
      contents.title = t('printpublisher.publish.print.steps.generate.generationError')
      contents.type = enums.notification.ERROR
      notify(contents)
    }
  }
}

/**
 * @type {import("vuex").ActionTree<state>}
 */
export const actions = {
  /**
   * Dispatch new batch of tasks
   * @param {Object} data Action data
   * @param {String} data.type Commit type
   * @param {String[]} data.tasks List of Task IDs
   */
  async tasks (context, data) {
    context.commit(data.type, enums.status.LOADING)

    try {
      const requests = data.tasks.map(task => api.status.tasks.poll({ id: task, interval: 10000 }))
      await Promise.all(requests)
      context.commit(data.type, enums.status.SUCCESS)
    } catch {
      context.commit(data.type, enums.status.ERROR)
    }
  }
}
