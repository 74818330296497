// Globals
import { NOTIFICATION_TIMEOUT } from '../../settings.js'

/**
 * @typedef {Object} Notification
 * @property {string} title Notification title
 * @property {string} content Notification body copy
 * @property {number} [timeout=4000] Time after which the notification will be removed
 * @property {Symbol} [type] Different notification types
 * @property {{text: string, action: Function}[]} [buttons] Do stuff
 */

export const namespaced = true

export const state = {
  list: {},
  previousID: 0
}

/**
 * @type {import("vuex").GetterTree<state>}
 */
export const getters = {
  /**
   * Get reversed list of notifications
   */
  reversedList (state) {
    const list = []

    for (const id in state.list) {
      list.push(state.list[id])
    }

    return list.reverse()
  }
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Reset state
   */
  reset (state) {
    state.list = {}
    state.previousID = 0
  },

  /**
   * Add new notification
   * @param {Notification} data
   */
  add (state, data) {
    const id = ++state.previousID
    const notification = {
      ...data,
      timeout: data.timeout ?? NOTIFICATION_TIMEOUT,
      id
    }

    state.list[id] = notification
  },

  /**
   * Remove notification
   * @param {number} id Notification ID
   */
  remove (state, id) {
    delete state.list[id]
  }
}
