<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

// Components
import CButton from './CButton.vue'

const store = useStore()
const isExpanded = computed(() => store.state.sidebarExpanded)

function toggleExpanded () {
  store.commit('sidebarExpanded', !isExpanded.value)
}
</script>

<template>
  <CButton
    :aria-label="`Sidebar ${isExpanded ? 'schließen' : 'öffnen'}`"
    :aria-expanded="isExpanded"
    :active="isExpanded"
    class="header-sidebar-button br-0"
    align="left"
    icon="menu"
    icon-size="1.375"
    @click="toggleExpanded"
  />
</template>

<style lang="scss">
@use "../css/variables";

.header-sidebar-button {
  @media (min-width: variables.$breakpoint-sidebar) {
    display: none;
  }
}
</style>
