import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get single product
 * @param {number} id Product ID
 * @returns {Promise<import('../../../types').ShopProduct>}
 */
export async function get (id) {
  const response = await connector.get(`laborshop/products/${id}`).json()
  store.commit('laborShop/addProduct', response)
  return response
}

/**
 * Get product list
 * @returns {Promise<import('../../../types').ShopProduct[]>}
 */
export async function getList () {
  const response = await connector.get('laborshop/products').json()
  store.dispatch('laborShop/addProductList', response)
  return response
}

/**
 * Create new product
 * @param {import('../../../types').ShopProduct} data Product data
 * @returns {Promise<import('../../../types').ShopProduct>}
 */
export async function create (data) {
  const response = await connector.post('laborshop/products', data, { timeout: 60 * 1000 }).json()
  store.commit('laborShop/addProduct', response)
  return response
}

/**
 * Update existing product
 * @param {number} id Product ID
 * @param {import('../../../types').ShopProduct} data Product data
 * @returns {Promise<import('../../../types').ShopProduct>}
 */
export async function update (id, data) {
  const response = await connector.patch(`laborshop/products/${id}`, data, { timeout: 60 * 1000 }).json()
  store.commit('laborShop/addProduct', response)
  return response
}

/**
 * Set product sorting
 * @param {{id: number, sort_index: number}[]} data Product sorting data
 */
export function sort (data) {
  return connector.post('laborshop/products/sorting', data)
}

/**
 * Remove product
 * @param {Object} options
 * @param {number} options.id Product ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`laborshop/products/${id}`)
  store.commit('laborShop/removeProduct', id)
  return response
}
