import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get single user info
 * @param {number} id User ID
 */
export function get (id) {
  return connector.get(`user/list/${id}`).json()
}

/**
 * Get current user info
 */
export async function getCurrent () {
  const response = await connector.get('user/current').json()
  store.commit('user/current', response)
  return response
}

/**
 * Get user list
 */
export function getList () {
  return connector.get('user/list').json()
}

/**
 * Create new user
 * @param {Object} data User data object
 */
export function create (data) {
  return connector.post('user/list', data).json()
}

/**
 * Update existing user
 * @param {number} id User ID
 * @param {Object} data User data object
 */
export function update (id, data) {
  return connector.patch(`user/list/${id}`, data).json()
}

/**
 * Delete existing user
 * @param {Object} options
 * @param {number} options.id User ID
 */
export function remove ({ id }) {
  return connector.delete(`user/list/${id}`)
}

/**
 * Get group list
 */
export function getGroups () {
  return connector.get('user/groups').json()
}
