// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'laborshop',
    component: () => import('../../views/VLaborShopIntro.vue')
  },
  {
    path: 'einrichtung',
    name: 'laborshop-setup',
    component: () => import('../../views/VLaborShopSetup.vue'),
    meta: {
      permission: () => userCan(enums.permissions.LABORSHOP, 'change_configuration'),
      title: 'Einrichtung'
    }
  },
  {
    path: 'e-mails',
    name: 'laborshop-emails',
    component: () => import('../../views/VLaborShopEmails.vue'),
    meta: {
      permission: () => userCan(enums.permissions.LABORSHOP, 'change_configuration'),
      title: 'E-Mails'
    }
  },
  {
    path: 'texte',
    name: 'laborshop-language',
    component: () => import('../../views/VLaborShopLanguage.vue'),
    meta: {
      permission: () => userCan(enums.permissions.LABORSHOP, 'change_configuration'),
      title: 'Texte'
    }
  },
  {
    path: 'artikel',
    component: () => import('../../views/VLaborShopEdit.vue'),
    meta: {
      permission: () => userCan(enums.permissions.LABORSHOP, 'view_category'),
      title: 'Artikel',
      sidebar: {
        content: import('../../components/CLaborShopSidebarEdit.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'laborshop-edit',
        component: () => import('../../views/VLaborShopEditIntro.vue')
      },
      {
        path: 'kategorie/neu',
        name: 'laborshop-edit-category-create',
        component: () => import('../../views/VLaborShopEditCategory.vue'),
        meta: {
          permission: () => userCan(enums.permissions.LABORSHOP, 'add_category'),
          title: 'Neue Kategorie'
        }
      },
      {
        path: 'kategorie/:id',
        name: 'laborshop-edit-category-edit',
        component: () => import('../../views/VLaborShopEditCategory.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Kategorie bearbeiten'
        }
      },
      {
        path: 'produkt/neu/:categoryID',
        name: 'laborshop-edit-product-create',
        component: () => import('../../views/VLaborShopEditProduct.vue'),
        props: route => ({
          categoryID: parseInt(route.params.categoryID)
        }),
        meta: {
          permission: () => userCan(enums.permissions.LABORSHOP, 'add_product'),
          title: 'Neues Produkt'
        }
      },
      {
        path: 'produkt/:id',
        name: 'laborshop-edit-product-edit',
        component: () => import('../../views/VLaborShopEditProduct.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Produkt bearbeiten'
        }
      }
    ]
  },
  {
    path: 'lieferformular',
    name: 'laborshop-delivery-form',
    component: () => import('../../views/VLaborShopDeliveryForm.vue'),
    meta: {
      permission: () => userCan(enums.permissions.LABORSHOP, 'change_configuration'),
      title: 'Lieferformular'
    }
  },
  {
    path: 'bestellungen',
    name: "laborshop-order-overview",
    component: () => import('../../views/VLaborShopOrderOverview.vue'),
    meta: {
      title: 'Bestellungen',
      sidebar: {
        content: import('../../components/CLaborShopSidebarList.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'laborshop-order-intro',
        component: () =>
          import('../../views/VLaborShopOrderOverviewIntro.vue')
      },
      {
        path: ':id',
        name: 'laborshop-order-detail',
        component: () => import('../../views/VLaborShopOrderDetail.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        })
      }
    ]
  },
  {
    path: 'vorschau',
    name: 'laborshop-preview',
    component: () => import('../../views/VLaborShopPreview.vue'),
    meta: {
      title: 'Vorschau'
    }
  },
  {
    path: ':pathMatch(.*)',
    redirect: { name: 'laborshop' }
  }
]
