import checkVersion from '../functions/checkVersion.js'
import generateTitle from '../functions/generateTitle.js'
import { VERSION_CHECK_FREQUENCY } from '../settings.js'

checkVersion()
let lastVersionCheck = new Date()

export default function (to, from, failure) {
  if (failure) {
    return
  }

  document.title = generateTitle(to.matched)

  const currentDate = new Date()

  if (currentDate - lastVersionCheck > VERSION_CHECK_FREQUENCY) {
    checkVersion()
    lastVersionCheck = currentDate
  }
}
