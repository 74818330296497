import connector from '../../connector.js'

/**
 * Get latest news
 * @returns {Promise<import('../../../types').News>} Latest news
 */
export function current () {
  return connector.get('motd/current').json()
}

/**
 * Get all news
 * @returns {Promise<import('../../../types').News[]>} News list
 */
export function getList () {
  return connector.get('motd/past').json()
}
