/**
 * Most enums should use Symbols to create unique ids during runtime.
 *
 * But sometimes the actual value has a meaning, most often if the backend
 * responds with some kind of "magic string".
 */

export default Object.freeze({
  // Frontend-only (use Symbols)

  status: {
    IDLE: Symbol('idle'),
    LOADING: Symbol('loading'),
    SUCCESS: Symbol('success'),
    ERROR: Symbol('error'),
    INVALID_TENANT: Symbol('invalid-tenant')
  },

  mode: {
    CREATE: Symbol('create'),
    EDIT: Symbol('edit')
  },

  notification: {
    SUCCESS: Symbol('success'),
    ERROR: Symbol('error')
  },

  // Shared with backend (use strings)

  modules: {
    INTERNAL: 'MD_INTRA',
    LABOREDITOR: 'MD_EDITOR',
    LABORSHOP: 'MD_SHOP',
    PRINTPUBLISHER: 'MD_PRINT',
    PROFICIENCY: 'MD_PROF',
    WEBPUBLISHER: 'MD_WEB'
  },

  tasks: {
    PRINTPUBLISHER_GENERATE: 'printpublisher-generation'
  },

  permissions: {
    AUTH: 'auth',
    GENERAL: 'general',
    LABOREDITOR: 'laboreditor',
    LABORSHOP: 'laborshop',
    PRINTPUBLISHER: 'printpublisher',
    PROFICIENCY: 'proficiency',
    STORAGE: 'core_storage',
    WEBPUBLISHER: 'webpublisher'
  },

  analysisState: {
    NEW: 'NEW',
    PUBLISHED: 'PUBLISHED',
    HIDDEN: 'HIDDEN'
  }
})
