import { createI18n } from 'vue-i18n'

import de from './locales/de.json'
import en from './locales/en.json'

let currentLanguage = localStorage.getItem("lp-current-language") 
if (!currentLanguage) {
  const navigatorLanguage = navigator.language
  localStorage.setItem("lp-current-language", navigatorLanguage)
  currentLanguage = navigatorLanguage 
}

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: currentLanguage,
  fallbackLocale: 'en',
  messages: {
    de,
    en
  }
})

export default i18n