/**
 * The thing to access in the state.
 * @typedef {'certificate'|'material'|'parameter'|'tag'} StateType
 */

// Globals
import api from '../../api'
import enums from '../../enums.js'

export const namespaced = true

function createState () {
  return {
    filter: {
      search: '',
      offset: 0
    },
    state: enums.status.IDLE,
    list: {
      previous: false,
      next: false,
      results: []
    }
  }
}

export const state = {
  certificate: createState(),
  material: createState(),
  parameter: createState(),
  tag: createState(),
  category: createState(),
  testable: createState(),
  certifiedTestable: createState(),
  certification: createState()
}

export const mutations = {
  /**
   * Reset state
   * @param {typeof state} state Vuex state
   */
  reset (state) {
    for (const item in state) {
      state[item] = createState()
    }
  },

  /**
   * Set filter
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {string} options.search Search term
   * @param {number} options.offset Offset value
   */
  filter (state, { type, search, offset }) {
    state[type].filter.search = search
    state[type].filter.offset = offset
  },

  /**
   * Set state
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {Symbol} options.status Status
   */
  state (state, { type, status }) {
    state[type].state = status
  },

  /**
   * Set list
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {boolean} options.previous Has previous items
   * @param {boolean} options.next Has next items
   * @param {Array} options.results Result list
   */
  list (state, { type, previous, next, results }) {
    state[type].list.previous = previous
    state[type].list.next = next
    state[type].list.results = results
  },

  /**
   * Remove item from list
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {number} options.id Item ID
   */
  remove (state, { type, id }) {
    const list = state[type].list.results
    const index = list.findIndex(tag => tag.id === id)
    list.splice(index, 1)
  }
}

export const actions = {
  /**
   * Get list and commit to store
   * @param {import('vuex').ActionContext<state>} context Vuex action context
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {{search: string, offset: number}} options.filter Filter
   */
  async list ({ state, commit }, { type, filter }) {
    try {
      commit('state', { type, status: enums.status.LOADING })

      const mergedFilter = { ...state[type].filter, ...filter }
      commit('filter', { type, ...mergedFilter })

      const response = await api.proficiency[type].getList(mergedFilter)
      commit('list', { type, ...response })

      commit('state', { type, status: enums.status.SUCCESS })
    } catch {
      commit('state', { type, status: enums.status.ERROR })
    }
  },

  /**
   * Remove item from stored list
   * @param {import('vuex').ActionContext<state>} context Vuex action context
   * @param {Object} options Options
   * @param {StateType} options.type State type
   * @param {number} options.id Item ID
   */
  async remove ({ commit }, { type, id }) {
    await api.proficiency[type].remove({ id })
    commit('remove', { type, id })
  }
}
