/**
 * This module cannot be named "export" because "export" cannot be imported!
 */

import connector from '../../connector.js'

/**
 * Trigger CSV generation
 * @param {Object} options
 * @param {string} [options.search] Search filter
 * @param {string} [options.pubstate] Publication state
 * @param {boolean} [options.internal] Internal analyses
 * @param {number} [options.category] Selected Category
 * @param {boolean} [options.escape] Escape HTML code
 * @param {string} [options.delimiter='|'] CSV delimiter
 * @returns {Promise<string>} Task ID
 */
export function start ({ search, pubstate, internal, category, escape, delimiter, language }) {
  const searchParams = { search, pubstate }

  if (internal) {
    searchParams.internal = internal
  }

  if (escape) {
    searchParams.escape = escape
  }

  if (delimiter) {
    searchParams.delimiter = delimiter
  }

  if (category) {
    searchParams.category = category
  }

  if (language) {
    searchParams.language = language
  }

  return connector.get('laboreditor/generate-csv-file', { searchParams }).json()
}

/**
 * Cancel CSV generation
 * @param {Object} options
 * @param {string} options.id Task ID
 */
export function cancel ({ id }) {
  return connector.post(`laboreditor/cancel-csv-generation/${id}`)
}

/**
 * Get latest CSV file
 * @returns {Promise<{filename: string, data: string}>}
 */
export async function get ({ language }) {
  const searchParams = { language }
  const response = await connector.get('laboreditor/get-csv-file', { searchParams })
  const data = await response.text()

  // The content-disposition header has the following format:
  // 'attachment; filename="…-export.csv"'
  const filename = response.headers
    .get('content-disposition')
    .match(/filename="(?<filename>.*)"/)
    .groups.filename

  return { filename, data }
}
