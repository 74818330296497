/**
 * Get sidebar from route
 * @param {import('vue-router').Route} route Current route
 * @param {'menu'|'content'} type Sidebar type
 * @returns {import('vue').Component} Sidebar component
 */
export default function (route, type) {
  return route.matched
    // Get all routes along the lineage with a sidebar.
    .filter(match => match.meta.sidebar?.[type])
    // Use the "youngest" child's sidebar.
    .reverse()[0]?.meta?.sidebar?.[type]
}
