// Globals
import enums from '../../enums.js'
import store from '../../store'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'login',
    component: () => import('../../views/VLoginForm.vue'),
    meta: {
      public: true,
      title: 'Login'
    }
  },
  {
    path: 'reset',
    name: 'login-reset-request',
    component: () => import('../../views/VLoginResetRequest.vue'),
    meta: {
      public: true,
      title: 'Passwort zurücksetzen'
    }
  },
  {
    path: 'reset/:token',
    name: 'login-reset-password',
    component: () => import('../../views/VLoginResetPassword.vue'),
    meta: {
      public: true,
      title: 'Passwort zurücksetzen'
    },
    props: route => ({
      token: route.params.token
    })
  },
  {
    path: 'nicht-gefunden',
    name: 'login-invalid-tenant',
    component: () => import('../../views/VLoginInvalidTenant.vue'),
    meta: {
      public: true
    },
    beforeEnter () {
      if (store.state.dataState === enums.status.INVALID_TENANT) {
        return true
      } else {
        return { name: 'dashboard' }
      }
    }
  }
]
