import { PROFICIENCY_LIST_COUNT } from '../../../settings.js'
import connector from '../../connector.js'

/**
 * Get tag
 * @param {Object} options
 * @param {number} options.id Tag ID
 */
export function get ({ id }) {
  return connector.get(`proficiency/tags/${id}`).json()
}

/**
 * Get tag list
 * @param {Object} filter Filter options
 * @param {string} filter.search Search term
 * @param {number} filter.offset Offset value
 * @param {number} [filter.limit=100] Number of results
 * @param {import('ky').Options} [options] Request options
 */
export function getList ({ search, offset, limit = PROFICIENCY_LIST_COUNT }, options) {
  const searchParams = { limit, search, offset }
  return connector.get('proficiency/tags', { searchParams, ...options }).json()
}

/**
 * Create new tag
 * @param {Object} options
 * @param {Object} options.data Tag data
 */
export function create ({ data }) {
  return connector.post('proficiency/tags', data).json()
}

/**
 * Update existing tag
 * @param {Object} options
 * @param {number} options.id Tag ID
 * @param {Object} options.data Tag data
 */
export function update ({ id, data }) {
  return connector.patch(`proficiency/tags/${id}`, data).json()
}

/**
 * Remove tag
 * @param {Object} options
 * @param {number} options.id Tag ID
 */
export function remove ({ id }) {
  return connector.delete(`proficiency/tags/${id}`)
}

export function preflight (id) {
  return connector.get(`proficiency/tags/${id}/delete-preflight`).json()
}
