// Globals
import api from '../../api'
import enums from '../../enums.js'

export const namespaced = true

function createState () {
  return {
    collectionId: null,
    filter: {
      search: '',
      offset: 0
    },
    listState: enums.status.IDLE,
    list: {
      previous: false,
      next: false,
      results: []
    },
    listItems: {
      previous: false,
      next: false,
      results: []
    }
  }
}

export const state = createState()

export const mutations = {
  /**
   * Reset state
   * @param {typeof state} state Vuex state
   */
  reset (state) {
    state = createState()
  },

  /**
   * Set filter
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {string} options.search Search term
   * @param {number} options.offset Offset value
   */
  filter (state, { search, offset }) {
    state.filter.search = search
    state.filter.offset = offset
  },

  /**
   * Set state
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {Symbol} options.status Status
   */
  state (state, { status }) {
    state.listState = status
  },

  /**
   * Set list
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {boolean} options.previous Has previous items
   * @param {boolean} options.next Has next items
   * @param {Array} options.results Result list
   */
  list (state, { previous, next, results }) {
    state.list.previous = previous
    state.list.next = next
    state.list.results = results
  },

  /**
   * Set list items
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {boolean} options.previous Has previous items
   * @param {boolean} options.next Has next items
   * @param {Array} options.results Result list
   */
  listItems (state, { previous, next, results }) {
    state.listItems.previous = previous
    state.listItems.next = next
    state.listItems.results = results
  },

  /**
   * Remove item from list
   * @param {typeof state} state Vuex state
   * @param {Object} options Options
   * @param {number} options.id Item ID
   */
  remove (state, { id }) {
    const list = state.list.results
    const index = list.findIndex(collection => collection.id === id)
    list.splice(index, 1)
  }
}

/**
 * @type {import("vuex").ActionTree<state>}
 */
export const actions = {
  async updateList (context) {
    context.commit('state', { state: enums.status.LOADING })

    try {
      const response = await api.laborEditor.collections.getList()
      context.commit('list', { ...response })
      context.commit('state', { status: enums.status.SUCCESS })
    } catch {
      context.commit('state', { status: enums.status.ERROR })
    }
  },

  async updateListItems (context) {
    context.commit('state', { status: enums.status.LOADING })

    try {
      const response = await api.laborEditor.collections.getListItems(context.state.collectionId, {
        offset: context.state.filter.offset,
        search: context.state.filter.search,
        limit: 100
      })

      context.commit('listItems', { ...response })
      context.commit('state', { status: enums.status.SUCCESS })
    } catch {
      context.commit('state', { status: enums.status.ERROR })
    }
  },

  /**
   * Set Collection ID
   * @param {typeof state} state Vuex state
   * @param {number} collectionId Collection ID to be used for all future actions
   */
  setCollectionId (context, collectionId) {
    context.state.collectionId = collectionId
    context.commit('filter', { search: '', offset: 0 })
    context.dispatch('updateListItems')
  }
}

/**
 * @type {import("vuex").ActionTree<state>}
 */
export const getters = {
  /**
   * Get the currently selected collection
   * @param {typeof state} state Vuex state
   */
  currentCollection (state) {
    const list = state.list.results
    const index = list.findIndex(collection => collection.id === state.collectionId)

    if (index !== -1) {
      return list[index]
    }

    return null
  }
}
