<script setup>
import { onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

// Components
import CHeader from '../components/CHeader.vue'
import CKeyboardHelp from '../components/CKeyboardHelp.vue'
import CModal from '../components/CModal.vue'
import CNotificationCenter from '../components/CNotificationCenter.vue'
import CSidebar from '../components/CSidebar.vue'

const store = useStore()
const router = useRouter()

const removeRouterAfterEach = router.afterEach(() => {
  store.commit('sidebarExpanded', false)
})

onUnmounted(() => {
  removeRouterAfterEach()
})
</script>

<template>
  <div class="main">
    <CHeader />
    <CNotificationCenter />

    <div class="main__wrapper">
      <CSidebar />
      <main class="main__content relative">
        <router-view />
      </main>
    </div>

    <div id="modal">
      <CKeyboardHelp />
      <CModal />
    </div>
  </div>
</template>

<style lang="scss">
@use "../css/variables";

// This following flexbox monstrosity ensures a content area that's always
// at least as high as the viewport for the lovely background icon.

.main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main__wrapper {
  display: flex;
  flex-grow: 1;
  margin-top: variables.$header-height;
}

.main__content {
  flex-grow: 1;

  // Prevent children from overflowing the flex container.
  min-width: 0;
}
</style>
