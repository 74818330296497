/**
 * The LaborPublisher frontend relies heavily on Vuex (maybe a bit too much).
 * So if you find some time, re-think some of the global states and move
 * them to local component states.
 */

import { createStore } from 'vuex'

// Globals
import enums from '../enums.js'
// Modules
import modules from './modules'

const state = {
  dataState: enums.status.IDLE,
  version: undefined,
  sidebarExpanded: false,
  modal: {
    open: false,
    closable: true,
    title: '',
    content: '',
    buttons: []
  },
  keyboardHelp: {
    open: false
  }
}

/**
 * @typedef {Object} ModalSettings
 * @property {string} title Modal title
 * @property {string} content Modal text content
 * @property {ModalButton[]} buttons Modal buttons
 */

/**
 * @typedef {Object} ModalButton
 * @property {string} text Button text
 * @property {string} color Button color
 * @property {Function} handler Execute on button click
 */

/**
 * @type {import("vuex").MutationTree<state>}
 */
const mutations = {
  /**
   * Set global loading state
   * @param {number} status Global loading state
   */
  dataState (state, status) {
    state.dataState = status
  },
  /**
   * Set LaborPublisher version
   * @param {string} version LaborPublisher version
   */
  version (state, version) {
    state.version = version
  },
  /**
   * Set global sidebar expanded status
   * @param {boolean} status Sidebar is expanded
   */
  sidebarExpanded (state, status) {
    state.sidebarExpanded = status
  },
  /**
   * Create new modal
   * @param {ModalSettings} settings Modal options
   */
  openModal (state, settings) {
    Object.assign(state.modal, settings, { open: true })
  },
  /**
   * Hide modal
   */
  closeModal (state) {
    state.modal.open = false
    state.modal.closable = true
  },
  /**
   * Show keyboard help overlay
   */
  openKeyboardHelp (state) {
    state.keyboardHelp.open = true
  },
  /**
   * Hide keyboard help overlay
   */
  closeKeyboardHelp (state) {
    state.keyboardHelp.open = false
  }
}

export default createStore({
  modules,
  state,
  mutations
})
