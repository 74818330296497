/** @type {'local'|'dev'|'stage'|'prod'} */
export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT || 'local'

export const LOCALSTORAGE_KEY_AUTH = 'lp-auth-token'

/** Query parameter key for the WebPublisher analysis code */
export const WEBPUBLISHER_CODE_KEY = 'wp_code'

/** Query parameter key for the WebPublisher page */
export const WEBPUBLISHER_PAGE_KEY = 'wp_page'

/** Query parameter key for WebPublisher search term */
export const WEBPUBLISHER_SEARCH_KEY = 'wp_search'
