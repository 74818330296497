import api from '../api'
import store from '../store'
import showNewVersion from './showNewVersion.js'
import showNoConnection from './showNoConnection.js'

/**
 * Check LaborPublisher version and force reload if the local version and the
 * remote version differ. This ensures all frontend assets are up-to-date.
 */
export default async function () {
  try {
    const version = await api.global.version.get()

    if (!store.state.version) {
      store.commit('version', version)
    } else if (version !== store.state.version) {
      showNewVersion(version)
    }
  } catch (error) {
    if (error.response?.status !== 404) {
      showNoConnection()
    }
  }
}
