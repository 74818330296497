import { LOCALSTORAGE_KEY_AUTH } from '@laborpublisher/global/config/settings.js'

/**
 * Save auth token as cookie
 * @param {string} token The auth token
 */
function save (token) {
  localStorage.setItem(LOCALSTORAGE_KEY_AUTH, token)
}

/**
 * Get auth token from cookies
 * @returns {string} Auth token
 */
function load () {
  return localStorage.getItem(LOCALSTORAGE_KEY_AUTH)
}

/**
 * Remove auth token from cookies
 */
function remove () {
  localStorage.removeItem(LOCALSTORAGE_KEY_AUTH)
}

export default {
  save,
  load,
  remove
}
