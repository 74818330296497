export const namespaced = true

export const state = {
  current: {}
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Reset state
   */
  reset (state) {
    state.current = {}
  },

  /**
   * Update current user
   * @param {Object} data User data
   */
  current (state, data) {
    state.current = data
  }
}
