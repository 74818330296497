import { GLOBAL_LIST_COUNT } from '../../../settings.js'
import connector from '../../connector.js'

/**
 * Get analysis list
 * @param {Object} filter Filter options
 * @param {number} [filter.limit=20] Number of items
 * @param {number} [filter.offset=0] Offset
 * @param {string} [filter.search=''] Search term
 * @param {import('ky').Options} [options] Request options
 * @returns {Promise<import('../../../types').AnalysisList>}
 */
export function getList ({ limit = GLOBAL_LIST_COUNT, offset = 0, search = '' }, options) {
  const searchParams = { limit, offset, search }
  return connector.get('core/analyses', { searchParams, ...options }).json()
}
