/**
 * The API is split into modules representing the different LaborPublisher
 * modules plus a few generic ones.
 */

import auth from './modules/auth'
import global from './modules/global'
import laborEditor from './modules/laborEditor'
import laborShop from './modules/laborShop'
import printPublisher from './modules/printPublisher'
import proficiency from './modules/proficiency'
import settings from './modules/settings'
import status from './modules/status'
import webPublisher from './modules/webPublisher'

export default {
  auth,
  global,
  laborEditor,
  laborShop,
  printPublisher,
  proficiency,
  settings,
  status,
  webPublisher
}
