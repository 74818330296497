<script setup>
import { computed, useAttrs } from 'vue'

// Globals
import enums from '../enums.js'
// Components
import CIcon from './CIcon.vue'

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
    validate: value => [
      'error',
      'ghost-dark',
      'ghost',
      'light',
      'lighter',
      'medium',
      'primary'
    ].includes(value)
  },
  size: {
    type: String,
    default: 'medium',
    validate: value => [
      'small',
      'medium'
    ].includes(value)
  },
  align: {
    type: String,
    default: 'center',
    validate: value => [
      'left',
      'center'
    ].includes(value)
  },
  status: {
    type: Symbol,
    default: undefined,
    validate: value => Object.values(enums).includes(value)
  },
  icon: {
    type: String,
    default: undefined
  },
  iconStart: {
    type: String,
    default: undefined
  },
  iconEnd: {
    type: String,
    default: undefined
  },
  iconSize: {
    type: [Number, String],
    default: undefined
  },
  disabled: {
    type: Boolean
  },
  exactActive: {
    type: Boolean
  }
})

const attrs = useAttrs()

const tag = computed(() => {
  if (attrs.for) {
    return 'label'
  }

  if (attrs.href) {
    return 'a'
  }

  if (attrs.to) {
    return 'router-link'
  }

  return 'button'
})

const disabledAttr = computed(() => {
  if (tag.value === 'button') {
    return props.disabled || props.status === enums.status.LOADING
  }

  return null
})

// The start icon can be dynamically replaced depending on the button status.
const iconStartDynamic = computed(() => {
  return {
    [enums.status.LOADING]: 'loading',
    [enums.status.SUCCESS]: 'success',
    [enums.status.ERROR]: 'error'
  }[props.status] ?? props.iconStart
})
</script>

<template>
  <component
    :is="tag"
    class="button br-2"
    :class="[
      `is-size-${size}`,
      `is-align-${align}`,
      `is-color-${color}`,
      {
        'active-if-exact': exactActive,
        'is-icon': icon,
        'is-disabled': disabled
      }
    ]"
    :disabled="disabledAttr"
  >
    <transition name="button__icon">
      <CIcon
        v-if="iconStartDynamic"
        :type="iconStartDynamic"
        :size="iconSize"
        class="button__icon-start"
      />
    </transition>

    <CIcon
      v-if="icon"
      :type="icon"
      :size="iconSize"
    />

    <slot v-else />

    <transition name="button__icon">
      <CIcon
        v-if="iconEnd"
        :type="iconEnd"
        :size="iconSize"
        class="button__icon-end"
      />
    </transition>
  </component>
</template>

<style lang="scss">
@use "../css/variables";

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  vertical-align: middle;

  padding: variables.$padding-input (variables.$padding-input * 1.25);

  font-size: 1em;
  line-height: variables.$line-height-input;
  text-align: center;
  text-decoration: none;
  color: var(--color-primary-text);

  background: var(--color-primary);

  transition:
    color 0.15s,
    background-color 0.15s,
    opacity 0.3s;

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):not(.active-if-exact).is-active,
  &:not(.is-disabled).is-exact-active {
    color: var(--color-primary-highlight-text);
    background: var(--color-primary-highlight);
  }

  &.is-color-lighter {
    color: var(--color-gray-700);
    background: var(--color-gray-300);

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      color: var(--color-gray-900);
      background: var(--color-gray-500);
    }
  }

  &.is-color-light {
    color: var(--color-gray-800);
    background: var(--color-gray-400);

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      color: var(--color-gray-900);
      background: var(--color-gray-500);
    }
  }

  &.is-color-medium {
    color: #fff;
    background: var(--color-gray-600);

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      background: var(--color-gray-700);
    }
  }

  &.is-color-ghost {
    color: var(--color-gray-700);
    background: none;

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      color: var(--color-gray-800);
      background: var(--color-gray-400);
    }
  }

  &.is-color-ghost-dark {
    color: var(--color-gray-100);
    background: none;

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      background: var(--color-gray-700);
    }
  }

  &.is-color-error {
    color: var(--color-red-100);
    background: var(--color-red-600);

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):not(.active-if-exact).is-active,
    &:not(.is-disabled).is-exact-active {
      background: var(--color-red-700);
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &.is-size-small {
    padding: (variables.$padding-input * 0.5) (variables.$padding-input * 0.75);
  }

  &.is-align-left {
    justify-content: flex-start;
    text-align: left;
  }

  &.is-icon {
    padding: variables.$padding-input;
  }

  &.is-icon.is-size-small {
    padding: (variables.$padding-input * 0.5);
  }
}

.button__icon-start {
  margin-right: 0.5em;
}

.button__icon-end {
  margin-left: 0.5em;
}

.button__icon-enter-from,
.button__icon-leave-to {
  width: 0;
  margin-left: 0;
  margin-right: 0;
  transform: scale(0.5);

  opacity: 0;
}

.button__icon-enter-to,
.button__icon-leave-from {
  width: 1em;
}

.button__icon-enter-active,
.button__icon-leave-active {
  transition:
    width 0.2s,
    margin-left 0.2s,
    margin-right 0.2s,
    transform 0.3s,
    opacity 0.3s;
}
</style>
