import * as chapter from './chapter.js'
import * as config from './config.js'
import * as docs from './docs.js'
import * as paragraph from './paragraph.js'
import * as publish from './publish.js'
import * as template from './template.js'

export default {
  chapter,
  config,
  docs,
  paragraph,
  publish,
  template
}
