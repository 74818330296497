import i18n from '../i18n'
import store from '../store'

const t = i18n.global.t

/**
 * Notify about read-only data in maintenance mode.
 */
export default function () {
  store.commit('openModal', {
    title: t('globals.modals.maintenance.title'),
    content: t('globals.modals.description.title'),
    buttons: [{
      text: t('globals.modals.description.continueReadonly'),
      color: 'light'
    }]
  })
}
