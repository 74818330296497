<script setup>
import { computed, ref } from 'vue'
// Composition
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// Components
import CButton from './CButton.vue'
import COverlay from './COverlay.vue'

const { t } = useI18n()
const store = useStore()

const title = computed(() => store.state.modal.title)
const cascadeDeletions = computed(() => store.state.modal.cascadeDeletions)
const content = computed(() => store.state.modal.content)
const buttons = computed(() => store.state.modal.buttons)

const isOpen = computed(() => store.state.modal.open)
const isClosable = computed(() => store.state.modal.closable)

const showListEntriesOnKey = ref(null)

function onClose() {
  showListEntriesOnKey.value = null
  store.commit('closeModal')
}

function onClick(handler) {
  if (handler) {
    handler()
  }

  onClose()
}
</script>

<template>
  <COverlay
    :open="isOpen"
    size="small"
    :closable="isClosable"
    :title="title"
    @close="onClose"
  >
    <div
      v-if="cascadeDeletions && Object.keys(cascadeDeletions).length"
      class="modal__cascading-deletions-container"
    >
      {{ t('globals.modals.remove.itemsToBeDeleted') }}

      <br>

      <div
        v-for="[key, values] in Object.entries(cascadeDeletions)"
        :key="key"
      >
        <p class="modal__cascading-deletions-list-title">
          {{ key }}
        </p>
        <ul
          v-if="values.length <= 3 || showListEntriesOnKey == key"
          class="modal__cascading-deletions-list"
        >
          <li
            v-for="item, index in values"
            :key="index"
            class="modal__cascading-deletions-item"
          >
            {{ item }}
          </li>
        </ul>
        <ul
          v-else
          class="modal__cascading-deletions-list modal__cascading-deletions-list--count"
        >
          <li class="modal__cascading-deletions-item modal__cascading-deletions-item--count">
            <CButton
              href="#"
              color="ghost"
              @click="showListEntriesOnKey = key"
            >
              <span v-html="t('globals.modals.remove.showAll', { count: values.length })" />
            </CButton>
          </li>
        </ul>
      </div>
    </div>

    <p>{{ content }}</p>

    <div
      v-if="buttons && buttons.length"
      class="flex justify-center gap-5 mt-5"
    >
      <CButton
        v-for="button in buttons"
        :key="button.text"
        :color="button.color"
        @click="onClick(button.handler)"
      >
        {{ button.text }}
      </CButton>
    </div>
  </COverlay>
</template>

<style>
.modal__cascading-deletions-container {
  max-height: 500px;
  overflow-y: auto;
}

.modal__cascading-deletions-list-title {
  font-weight: 700;
  margin-top: 1em;
}

.modal__cascading-deletions-list {
  list-style: disc;
  margin: 0 0.5em 1em;
  padding: 0.5em 2em;
}

.modal__cascading-deletions-item--count {
  font-style: italic;
}
</style>
