import * as collections from './collections.js'
import * as laborEditor from './laborEditor.js'
import * as laborShop from './laborShop.js'
import * as notifications from './notifications.js'
import * as printPublisher from './printPublisher.js'
import * as proficiency from './proficiency.js'
import * as settings from './settings.js'
import * as status from './status.js'
import * as user from './user.js'

export default {
  collections,
  laborEditor,
  laborShop,
  notifications,
  printPublisher,
  proficiency,
  settings,
  status,
  user
}
