<script setup>
import { computed } from 'vue'
// Composition
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// Components
import CGrid from './CGrid.vue'
import COverlay from './COverlay.vue'
import CTitle from './CTitle.vue'

const { t } = useI18n()
const store = useStore()
const isOpen = computed(() => store.state.keyboardHelp.open)

function onClose () {
  store.commit('closeKeyboardHelp')
}
</script>

<template>
  <COverlay
    :open="isOpen"
    size="large"
    :title="t('shortcuts.title')"
    closable
    @close="onClose"
  >
    <CGrid min="20em">
      <section>
        <CTitle
          level="3"
          class="mb-3"
        >
          {{ t('shortcuts.forms') }}
        </CTitle>

        <table class="table-plain">
          <tr>
            <td>
              <kbd>{{ t('shortcuts.keys.control') }}</kbd>&nbsp;<kbd>S</kbd> /
              <kbd>⌘</kbd>&nbsp;<kbd>S</kbd>
            </td>
            <td>{{ t('globals.save') }}</td>
          </tr>
        </table>
      </section>

      <section>
        <CTitle
          level="3"
          class="mb-3"
        >
          {{ t('shortcuts.lists') }}
        </CTitle>

        <table class="table-plain">
          <tr>
            <td><kbd>{{ t('shortcuts.keys.shift') }}</kbd>&nbsp;<kbd>↑</kbd></td>
            <td>{{ t('shortcuts.moveUp') }}</td>
          </tr>
          <tr>
            <td><kbd>{{ t('shortcuts.keys.shift') }}</kbd>&nbsp;<kbd>↓</kbd></td>
            <td>{{ t('shortcuts.moveDown') }}</td>
          </tr>
        </table>
      </section>
    </CGrid>
  </COverlay>
</template>
