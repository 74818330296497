<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const colors = computed(() => {
  const userColors = store.state.settings.public.colors
  const colorVariables = {}

  for (const name in userColors) {
    colorVariables[`--color-${name}`] = userColors[name]
  }

  return colorVariables
})
</script>

<template>
  <router-view :style="colors" />
</template>
