import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get LaborShop config
 * @returns {Promise<import('../../../types').ShopConfig>}
 */
export async function get () {
  const response = await connector.get('laborshop/config').json()
  store.commit('laborShop/updateConfig', response)
  return response
}

/**
 * Update LaborShop config
 * @param {import('../../../types').ShopConfig} data Config data object
 * @returns {Promise<import('../../../types').ShopConfig>}
 */
export async function update (data) {
  const response = await connector.patch('laborshop/config/1', data).json()
  store.commit('laborShop/updateConfig', response)
  return response
}
