import router from '../router'
import reset from './reset.js'

/**
 * Clear user information and redirect to login
 */
export default function () {
  reset()
  router.push({ name: 'login' })
}
