import connector from '../../connector.js'

/**
 * Get configuration
 */
export async function get () {
  const [response] = await connector.get('proficiency/configuration').json()
  return response
}

/**
 * Update configuration
 * @param {Object} data Configuration data
 */
export function update (data) {
  return connector.patch('proficiency/configuration/1', data).json()
}
