<script setup>
import {
  mdiAccountMultipleOutline,
  mdiAccountOutline,
  mdiAlertCircle,
  mdiAlertDecagram,
  mdiArrowLeft,
  mdiArrowRight,
  mdiBellOutline,
  mdiBookOpenOutline,
  mdiBookOpenVariant,
  mdiBookOutline,
  mdiBookPlusMultipleOutline,
  mdiBookPlusOutline,
  mdiCart,
  mdiCertificateOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronUp,
  mdiClipboardCheck,
  mdiClose,
  mdiCodeJson,
  mdiCogOutline,
  mdiCropLandscape,
  mdiCropPortrait,
  mdiDatabaseImport,
  mdiDelete,
  mdiDotsVertical,
  mdiDragVertical,
  mdiEarth,
  mdiEmailOutline,
  mdiEyedropper,
  mdiEyeOff,
  mdiFileDocumentEditOutline,
  mdiFileOutline,
  mdiFileUploadOutline,
  mdiFlask,
  mdiFormatColumns,
  mdiFormatListNumbered,
  mdiHelpCircleOutline,
  mdiLoading,
  mdiLock,
  mdiMagnify,
  mdiMenu,
  mdiMicroscope,
  mdiMonitorDashboard,
  mdiOpenInNew,
  mdiPackageVariantClosed,
  mdiPaletteSwatch,
  mdiPencil,
  mdiPlaylistEdit,
  mdiPlaylistRemove,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiRefresh,
  mdiSyncCircle,
  mdiTagTextOutline,
  mdiTextBoxCheckOutline,
  mdiTextLong,
  mdiTranslate,
  mdiTune,
  mdiUploadNetworkOutline,
  mdiViewDashboard,
  mdiWeb} from '@mdi/js'
import { ref, watch } from 'vue'

const paths = {
  'account-multiple': mdiAccountMultipleOutline,
  'add-outline': mdiPlusCircleOutline,
  'arrow-left': mdiArrowLeft,
  'arrow-right': mdiArrowRight,
  'checkbox-checked': mdiCheckboxMarked,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  'clipboard-check': mdiClipboardCheck,
  'create-single': mdiBookPlusOutline,
  'create-multiple': mdiBookPlusMultipleOutline,
  'database-import': mdiDatabaseImport,
  'dots-vertical': mdiDotsVertical,
  'drag-vertical': mdiDragVertical,
  'edit-items': mdiPlaylistEdit,
  'file-upload': mdiFileUploadOutline,
  'list-numbered': mdiFormatListNumbered,
  'network-upload': mdiUploadNetworkOutline,
  'radio-checked': mdiRadioboxMarked,
  'text-columns': mdiFormatColumns,
  account: mdiAccountOutline,
  add: mdiPlus,
  bell: mdiBellOutline,
  category: mdiPaletteSwatch,
  tag: mdiTagTextOutline,
  certificate: mdiCertificateOutline,
  checkbox: mdiCheckboxBlankOutline,
  close: mdiClose,
  cover: mdiBookOutline,
  dashboard: mdiViewDashboard,
  delete: mdiDelete,
  edit: mdiPencil,
  email: mdiEmailOutline,
  error: mdiAlertCircle,
  external: mdiOpenInNew,
  file: mdiFileOutline,
  help: mdiHelpCircleOutline,
  hidden: mdiEyeOff,
  json: mdiCodeJson,
  laboreditor: mdiFileDocumentEditOutline,
  laborshop: mdiCart,
  landscape: mdiCropLandscape,
  language: mdiTranslate,
  loading: mdiLoading,
  lock: mdiLock,
  material: mdiFlask,
  menu: mdiMenu,
  testable: mdiMicroscope,
  new: mdiAlertDecagram,
  package: mdiPackageVariantClosed,
  parameter: mdiEyedropper,
  portrait: mdiCropPortrait,
  preview: mdiMonitorDashboard,
  printpublisher: mdiBookOpenVariant,
  proficiency: mdiCheckboxMarkedCircleOutline,
  public: mdiEarth,
  publish: mdiTextBoxCheckOutline,
  radio: mdiRadioboxBlank,
  refresh: mdiRefresh,
  search: mdiMagnify,
  settings: mdiCogOutline,
  setup: mdiTune,
  success: mdiCheckCircle,
  template: mdiBookOpenOutline,
  text: mdiTextLong,
  unlisted: mdiPlaylistRemove,
  update: mdiSyncCircle,
  webpublisher: mdiWeb
}

const props = defineProps({
  size: {
    type: [Number, String],
    default: 1
  },
  align: {
    type: String,
    default: undefined
  },
  type: {
    type: String,
    required: true
  },
  dot: {
    type: Boolean
  }
})

const pathName = ref(props.type)
const transitionPath = ref(false)

watch(() => props.type, type => {
  transitionPath.value = true

  // Sync timeout with animation duration.
  const timeout = 200

  setTimeout(() => {
    pathName.value = type
  }, timeout / 2)

  setTimeout(() => {
    transitionPath.value = false
  }, timeout)
})
</script>

<template>
  <span
    class="icon"
    :class="{
      'is-transitioning': transitionPath,
      'has-dot': dot
    }"
    :style="{
      'font-size': `${size}em`,
      'vertical-align': align
    }"
  >
    <svg
      aria-hidden="true"
      class="icon__svg"
      :class="{
        'is-rotating': pathName === 'loading'
      }"
      viewBox="0 0 24 24"
    >
      <path :d="paths[pathName]" />
    </svg>
  </span>
</template>

<style lang="scss">
.icon {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  // Don’t shrink icons if they’re inside a flex container.
  flex-shrink: 0;

  pointer-events: none;

  &.is-transitioning {
    // Sync animation duration with timeout.
    animation: transition 0.2s ease-in-out;
  }

  &.has-dot::after {
    content: "";

    position: absolute;
    top: -0.0625em;
    right: -0.125em;
    width: 0.5em;
    height: 0.5em;

    background: var(--color-red-600);
    border-radius: 50%;
  }
}

// Split the actual icon path into a separate element. This allows parent
// components to animate the outer element with `<transition>` and still
// continue the icon animation.
.icon__svg {
  width: 1em;
  height: 1em;

  &.is-rotating {
    animation: rotate 0.8s linear infinite;
  }
}

@keyframes transition {
  50% {
    transform: scale(0.7);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
