export const namespaced = true

export const state = {
  public: {},
  bookedModules: {}
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Update public settings
   * @param {Object} data Public settings data
   */
  public (state, data) {
    state.public = data
  },

  /**
   * Save booked modules
   * @param {Object} data Booked modules
   */
  bookedModules (state, data) {
    state.bookedModules = data
  }
}
