/**
 * The API connector is a ky instance that handles global request processing,
 * like attaching headers, dealing with invalid tokens etc.
 */

/**
 * @typedef {Object|FormData} RequestData
 */

/**
 * @typedef {import('ky').Options} RequestOptions
 */

import ky from 'ky'

// Functions
import token from '../functions/token.js'
import userClear from '../functions/userClear.js'

/**
 * Add auth header to request
 * @type {import('ky').BeforeRequestHook}
 */
function addAuthHeader (request) {
  const authToken = token.load()

  if (authToken) {
    request.headers.set('Authorization', `Token ${authToken}`)
  }
}

/**
 * Handle invalid token response
 * @type {import('ky').AfterResponseHook}
 */
async function handleInvalidToken (request, options, response) {
  if (response.status === 401) {
    const body = await response.json()

    if (body.detail === 'Ungültiges Token') {
      userClear()
      location.reload()
    }
  }
}

const defaultKyConfig = {
  retry: 3,
  hooks: {
    beforeRequest: [addAuthHeader],
    afterResponse: [handleInvalidToken]
  }
}

const connector = ky.create({
  prefixUrl: '/api/',
  ...defaultKyConfig
})

function createLanguageConnector (lang) {
  let langConnector

  if (lang) {
    langConnector = ky.create({
      prefixUrl: `/api/${lang}/`,
      ...defaultKyConfig
    })
  } else {
    langConnector = connector
  }

  return {
    get (path, options) {
      return langConnector.get(path + '/', options)
    },

    patch (path, data, options) {
      return langConnector.patch(path + '/', makeOptions(data, options))
    }
  }
}

/**
 * Generate request options
 * @param {RequestData} data Request data
 * @param {RequestOptions} options Request options
 * @returns {RequestOptions} Complete request options
 */
function makeOptions (data, options) {
  const type = data instanceof FormData ? 'body' : 'json'
  return { [type]: data, ...options }
}

export default {
  withLang: createLanguageConnector,
  /**
   * GET an API endpoint
   * @param {string} path API path
   * @param {RequestOptions} [options] Request options
   */
  get (path, options) {
    return connector.get(path + '/', options)
  },

  /**
   * PATCH an API endpoint
   * @param {string} path API path
   * @param {RequestData} data Request data
   * @param {RequestOptions} [options] Request options
   */
  patch (path, data, options) {
    return connector.patch(path + '/', makeOptions(data, options))
  },

  /**
   * POST an API endpoint
   * @param {string} path API path
   * @param {RequestData} [data] Request data
   * @param {RequestOptions} [options] Request options
   */
  post (path, data, options) {
    return connector.post(path + '/', makeOptions(data, options))
  },

  /**
   * DELETE an API endpoint
   * @param {string} path API path
   * @param {RequestOptions} [options] Request options
   */
  delete (path, options) {
    return connector.delete(path + '/', options)
  }
}
