// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'laboreditor',
    component: () => import('../../views/VLaborEditorIntro.vue')
  },
  {
    path: 'analyse',
    component: () => import('../../views/VLaborEditorAnalysisEdit.vue'),
    meta: {
      permission: () => userCan(enums.permissions.STORAGE, 'view_flatanalysis'),
      title: 'Analyse bearbeiten',
      sidebar: {
        content: import('../../components/CLaborEditorSidebarList.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'laboreditor-analysis-edit',
        component: () =>
          import('../../views/VLaborEditorAnalysisEditIntro.vue')
      },
      {
        path: ':id/:lang?',
        name: 'laboreditor-analysis-edit-form',
        component: () => import('../../views/VLaborEditorAnalysisEditForm.vue'),
        props: (route) => ({
          id: route.params.id,
          lang: route.params.lang
        })
      }
    ]
  },
  {
    path: 'analyse/neu',
    component: () => import('../../views/VLaborEditorAnalysisCreate.vue'),
    meta: {
      permission: () => userCan(enums.permissions.STORAGE, 'add_flatanalysis'),
      title: 'Neue Analyse',
      sidebar: {
        content: import('../../components/CLaborEditorSidebarList.vue')
      }
    },
    children: [
      {
        path: 'kategorie',
        name: 'laboreditor-analysis-create',
        component: () =>
          import('../../views/VLaborEditorAnalysisCreateCategory.vue')
      },
      {
        path: ':categories?',
        name: 'laboreditor-analysis-create-form',
        component: () =>
          import('../../views/VLaborEditorAnalysisCreateForm.vue'),
        props: (route) => ({
          initialCategories: route.params.categories
            ? route.params.categories.split(',')
            : []
        })
      }
    ]
  },
  {
    path: 'collections/:collectionId',
    name: 'laboreditor-collection-edit',
    component: () => import('../../views/VLaborEditorCollectionEdit.vue'),
    props: (route) => ({
      collectionId: parseInt(route.params.collectionId)
    }),
    meta: {
      permission: () => userCan(enums.permissions.STORAGE, 'view_flatanalysis'),
      title: 'Collection bearbeiten',
      sidebar: {
        content: import('../../components/CLaborEditorCollectionSidebarList.vue')
      }
    },
    children: [
      {
        path: 'item/neu',
        name: 'laboreditor-collection-item-create',
        component: () => import('../../views/VLaborEditorCollectionEditForm.vue'),
        props: (route) => ({
          collectionId: parseInt(route.params.collectionId)
        })
      },
      {
        path: 'item/:itemId',
        name: 'laboreditor-collection-item-edit',
        component: () => import('../../views/VLaborEditorCollectionEditForm.vue'),
        props: (route) => ({
          collectionId: parseInt(route.params.collectionId),
          collectionItemId: parseInt(route.params.itemId)
        })
      }
    ]
  },
  {
    path: ':pathMatch(.*)',
    redirect: { name: 'laboreditor' }
  }
]
