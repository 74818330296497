import flattenAnalysisCategories from '../../../functions/flattenAnalysisCategories'
import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get category list
 * @returns {import('../../../types').AnalysisCategory[]}
 */
export async function getList () {
  return connector.get('core/categories').json()
}

export async function getListWithCommit () {
  const response = await connector.get('core/categories').json()
  store.commit('laborEditor/listCategories', flattenAnalysisCategories(response))
  return response
}
