import token from '../../../functions/token.js'
import userClear from '../../../functions/userClear.js'
import connector from '../../connector.js'

/**
 * Log user in
 * @param {Object} options
 * @param {string} options.username The username
 * @param {string} options.password The password
 */
export async function login ({ username, password }) {
  const hashedCredentials = btoa(`${username}:${password}`)

  const response = await connector.post('auth/verification/login', null, {
    headers: { Authorization: `Basic ${hashedCredentials}` }
  }).json()

  token.save(response.token)
  return response
}

/**
 * Log user out
 */
export async function logout () {
  try {
    const response = await connector.post('auth/verification/logout')
    userClear()

    return response
  } catch (error) {
    // Invalid token clearing is already handled by global request hook.
    if (error.response?.code !== 'authentication_failed') {
      userClear()
    }

    return error
  }
}
