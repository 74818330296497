import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get template list
 */
export async function getList () {
  const response = await connector.get('printpublisher/templates').json()
  store.commit('printPublisher/saveTemplates', response)
  return response
}

/**
 * Get layout list
 */
export async function getLayouts () {
  const response = await connector.get('printpublisher/layouts').json()
  store.commit('printPublisher/saveLayouts', response)
  return response
}

/**
 * Create template
 * @param {Object} data Template data
 */
export async function create (data) {
  const response = await connector.post('printpublisher/templates', data).json()
  store.commit('printPublisher/addTemplate', response)
  return response
}

/**
 * Update template
 * @param {number} id Template ID
 * @param {Object} data Template data
 */
export async function update (id, data) {
  const response = await connector.patch(`printpublisher/templates/${id}`, data).json()
  store.commit('printPublisher/addTemplate', response)
  return response
}

/**
 * Remove template
 * @param {Object} options
 * @param {number} options.id Template ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`printpublisher/templates/${id}`)
  store.commit('printPublisher/removeTemplate', id)
  return response
}
