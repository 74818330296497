<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

// Functions
import getSidebarFromRoute from '../functions/getSidebarFromRoute.js'
import CHeaderModules from './CHeaderModules.vue'
// Components
import CHeaderSettings from './CHeaderSettings.vue'
import CHeaderSidebarButton from './CHeaderSidebarButton.vue'

const route = useRoute()

const hasSidebar = computed(() => {
  if (getSidebarFromRoute(route, 'menu')) {
    return true
  }

  if (getSidebarFromRoute(route, 'content')) {
    return true
  }

  return false
})
</script>

<template>
  <header class="header bs-large bg-primary">
    <CHeaderSidebarButton v-if="hasSidebar" />

    <span class="header__signet px-4 text-primary-text">
      <img
        alt="LaborPublisher"
        class="header__logo"
        height="28"
        src="../assets/icon-white.svg"
        width="28"
      >
      <span class="header__title">LaborPublisher</span>
    </span>

    <CHeaderModules />
    <CHeaderSettings />
  </header>
</template>

<style lang="scss">
@use "../css/variables";

.header {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: variables.$header-height;
  z-index: variables.$z-index-navigation;
}

.header__signet {
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}

.header__logo {
  height: 1.5em;
}

.header__title {
  display: none;

  @media (min-width: variables.$breakpoint-header-menu-logo) {
    display: inline-block;
    margin-left: 0.75em;
  }
}
</style>
