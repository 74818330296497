import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get PrintPublisher config
 */
export async function get () {
  const response = await connector.get('printpublisher/config').json()
  store.commit('printPublisher/updateConfig', response)
  return response
}

/**
 * Update PrintPublisher config
 * @param {Object} data Config data object
 */
export async function update (data) {
  const response = await connector.patch('printpublisher/config/1', data, { timeout: 60 * 1000 }).json()
  store.commit('printPublisher/updateConfig', response)
  return response
}
