// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'
import store from '../../store'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'webpublisher',
    component: () => import('../../views/VWebPublisherIntro.vue')
  },
  {
    path: 'einrichtung',
    component: () => import('../../views/VWebPublisherSetup.vue'),
    meta: {
      permission: () => userCan(enums.permissions.WEBPUBLISHER, 'change_configurationmodel'),
      title: 'Einrichtung',
      sidebar: {
        content: import('../../components/CWebPublisherSidebarSetup.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'webpublisher-setup',
        component: () => import('../../views/VWebPublisherSetupIntro.vue')
      },
      {
        path: 'app',
        name: 'webpublisher-setup-app',
        component: () => import('../../views/VWebPublisherSetupApp.vue'),
        meta: {
          title: 'WebPublisher-App'
        }
      },
      {
        path: 'suche',
        name: 'webpublisher-setup-search',
        component: () => import('../../views/VWebPublisherSetupSearch.vue'),
        meta: {
          title: 'Eigenständige Suche'
        }
      },
      {
        path: 'ergebnisse',
        name: 'webpublisher-setup-result',
        component: () => import('../../views/VWebPublisherSetupResult.vue'),
        meta: {
          title: 'Eigenständige Ergebnisse'
        }
      },
      {
        path: 'api',
        name: 'webpublisher-setup-api',
        component: () => import('../../views/VWebPublisherSetupAPI.vue'),
        meta: {
          title: 'Developer-API'
        }
      }
    ]
  },
  {
    path: 'texte',
    name: 'webpublisher-language',
    component: () => import('../../views/VWebPublisherLanguage.vue'),
    meta: {
      permission: () => userCan(enums.permissions.WEBPUBLISHER, 'change_configurationmodel'),
      title: 'Texte'
    }
  },
  {
    path: 'vorschau',
    name: 'webpublisher-preview',
    component: () => import('../../views/VWebPublisherPreviewPublic.vue'),
    meta: {
      title: 'Vorschau'
    }
  },
  {
    path: 'vorschau/intern',
    name: 'webpublisher-preview-internal',
    component: () => import('../../views/VWebPublisherPreviewInternal.vue'),
    meta: {
      permission: () => store.state.settings.bookedModules[enums.modules.INTERNAL],
      title: 'Vorschau'
    }
  },
  {
    path: ':pathMatch(.*)',
    redirect: { name: 'webpublisher' }
  }
]
