import connector from '../../connector.js'

/**
 * Get WebPublisher settings
 */
export function get () {
  return connector.get('webpublisher/config').json()
}

/**
 * Update WebPublisher settings
 * @param {Object} data Settings data object
 */
export function update (data) {
  return connector.patch('webpublisher/config', data).json()
}
