import enums from '../../../enums.js'
import { LABORSHOP_LIST_COUNT } from '../../../settings.js'
import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get LaborShop order list
 * @returns {Promise<import('../../../types').OrderList>}
 */
export async function getList({ limit = LABORSHOP_LIST_COUNT, offset = 0, search = '' }) {
  const searchParams = { limit, offset, search }
  const response = await connector.get('laborshop/orders', { searchParams }).json()
  store.commit('laborShop/updateList', response)
  store.commit('laborShop/orderListState', enums.status.SUCCESS)
  return response
}

/**
 * Get LaborShop order detail
 * @returns {Promise<import('../../../types').Order>}
 */
export async function getDetail(id) {
  const response = await connector.get(`laborshop/orders/${id}`).json()
  return response
}

export async function sendOptIn(id) {
  const response = await connector.post(`laborshop/orders/${id}/send-opt-in`)
  return response
}

export async function sendCustomerConfirmation(id) {
  const response = await connector.post(`laborshop/orders/${id}/send-customer-confirmation`)
  return response
}

export async function sendLabConfirmation(id) {
  const response = await connector.post(`laborshop/orders/${id}/send-lab-confirmation`)
  return response
}
