import { PROFICIENCY_LIST_COUNT } from '../../../settings.js'
import connector from '../../connector.js'

/**
 * Get parameter
 * @param {Object} options
 * @param {number} options.id Parameter ID
 */
export function get ({ id }) {
  return connector.get(`proficiency/parameters/${id}`).json()
}

/**
 * Get parameter list
 * @param {Object} filter Filter options
 * @param {string} filter.search Search term
 * @param {number} filter.offset Offset value
 * @param {number} [filter.limit=100] Number of results
 * @param {import('ky').Options} [options] Request options
 */
export function getList ({ search, offset, limit = PROFICIENCY_LIST_COUNT }, options) {
  const searchParams = { limit, search, offset }
  return connector.get('proficiency/parameters', { searchParams, ...options }).json()
}

/**
 * Create new parameter
 * @param {Object} options
 * @param {Object} options.data Parameter data
 */
export function create ({ data }) {
  return connector.post('proficiency/parameters', data).json()
}

/**
 * Update existing parameter
 * @param {Object} options
 * @param {number} options.id Parameter ID
 * @param {Object} options.data Parameter data
 */
export function update ({ id, data }) {
  return connector.patch(`proficiency/parameters/${id}`, data).json()
}

/**
 * Remove parameter
 * @param {Object} options
 * @param {number} options.id Parameter ID
 */
export function remove ({ id }) {
  return connector.delete(`proficiency/parameters/${id}`).json()
}

export function preflight (id) {
  return connector.get(`proficiency/parameters/${id}/delete-preflight`).json()
}
