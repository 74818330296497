import connector from '../../connector.js'

/**
 * Request password reset email
 * @param {Object} options
 * @param {string} options.username Send an email to this user
 */
export function getToken ({ username }) {
  return connector.post('auth/password-reset/get-token', { username })
}

/**
 * Set new password with a token
 * @param {Object} options
 * @param {string} options.token Password reset token
 * @param {string} options.password New password
 * @param {string} options.passwordRetype New password again
 */
export function useToken ({ token, password, passwordRetype }) {
  return connector.post('auth/password-reset/use-token', {
    token,
    password,
    password_retype: passwordRetype
  })
}

/**
 * Check token validity
 * @param {Object} options
 * @param {string} options.token Password reset token
 */
export function verifyToken ({ token }) {
  return connector.post('auth/password-reset/verify-token', { token })
}
