import store from '../store'
import token from './token.js'

/**
 * Reset the whole application; including cookies, localStorage, and Vuex.
 */
export default function reset () {
  // User token
  token.remove()

  // Vuex
  store.commit('laborShop/reset')
  store.commit('notifications/reset')
  store.commit('printPublisher/reset')
  store.commit('proficiency/reset')
  store.commit('user/reset')

  // Local storage
  localStorage.clear()
}
