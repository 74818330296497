import { init } from '@sentry/vue'

import { APP_ENVIRONMENT } from '../config/settings.js'

/**
 * Attach Sentry to app
 * @param {Object} options
 * @param {string} options.dsn Sentry DSN
 * @param {import('vue').App} options.app Vue app instance
 */
export default function({ dsn, app }) {
  init({
    dsn,
    app,
    environment: APP_ENVIRONMENT,
    logErrors: true
  })
}
