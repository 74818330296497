import enums from '../../../enums.js'
import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get active tasks
 */
export async function getActive () {
  const response = await connector.get('status/tasks/active').json()
  const printPublisherTasks = response[enums.tasks.PRINTPUBLISHER_GENERATE]

  if (printPublisherTasks) {
    store.dispatch('status/tasks', {
      type: 'printPublisherGenerate',
      tasks: printPublisherTasks
    })
  }
}

/**
 * Get task heartbeat
 * @param {string} id Task ID
 */
function heartbeat (id) {
  return connector.get(`status/tasks/heartbeat/${id}`).json()
}

/**
 * Poll task heartbeat every so often
 * @param {Object} options
 * @param {string} options.id Task ID
 * @param {number} options.interval Polling interval in milliseconds
 * @param {AbortSignal} [options.signal] Abort signal
 */
export function poll ({ id, interval, signal }) {
  return new Promise((resolve, reject) => {
    const intervalID = setInterval(() => {
      heartbeat(id)
        .then(response => {
          if (response.finished) {
            clearInterval(intervalID)

            if (response.error) {
              reject(new Error('TaskError'))
            } else {
              resolve()
            }
          }
        })
        .catch(() => {
          clearInterval(intervalID)
          reject(new Error('TaskError'))
        })
    }, interval)

    signal?.addEventListener('abort', () => {
      reject(new Error('AbortError'))
      clearInterval(intervalID)
    })
  })
}
