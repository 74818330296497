/**
 * Generate browser title
 * @param {import('vue-router').RouteLocationMatched[]} path Path to route
 * @returns {string} Browser title
 */
export default function (path) {
  const titles = path
    .map(item => item.meta.title)
    .filter(Boolean)
    .reverse()

  titles.push('LaborPublisher')

  return titles.join(' | ')
}
