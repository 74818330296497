<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

// Components
import CNotification from './CNotification.vue'

const store = useStore()
const notifications = computed(() => store.getters['notifications/reversedList'])

function onClose (id) {
  store.commit('notifications/remove', id)
}
</script>

<template>
  <aside
    aria-label="Benachrichtigungen"
    aria-live="polite"
    class="notification-center"
  >
    <transition-group
      class="notification-center__list"
      name="notification-center__list"
      tag="ul"
    >
      <CNotification
        v-for="notification in notifications"
        :key="notification.id"
        class="mb-5"
        v-bind="notification"
        @close="onClose(notification.id)"
      />
    </transition-group>
  </aside>
</template>

<style lang="scss">
@use "../css/variables";

.notification-center {
  position: fixed;
  top: 1em;
  right: 1em;
  width: 24em;
  max-width: calc(100% - #{1em * 2});
  z-index: variables.$z-index-notifications;
}

// Transition

.notification-center__list {
  position: relative;
}

.notification-center__list-move,
.notification-center__list-enter-active,
.notification-center__list-leave-active {
  @media (prefers-reduced-motion: no-preference) {
    transition: transform 0.4s;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: opacity 0.2s;
  }
}

.notification-center__list-enter-from,
.notification-center__list-leave-to {
  @media (prefers-reduced-motion: no-preference) {
    transform: translateX(calc(100% + #{1em}));
  }

  @media (prefers-reduced-motion: reduce) {
    opacity: 0;
  }
}

.notification-center__list-leave-active {
  // stylelint-disable-next-line declaration-no-important
  position: absolute !important;
}
</style>
