import connector from '../../connector.js'

/**
 * Preprocess form field for better compatibility with Vue.
 * @param {import('../../../types').AnalysisField} field Form field
 * @returns {import('../../../types').AnalysisField} Processed form field
 */
function preprocessFields (field) {
  const { type, raw, additional, required } = field

  // Set override to true if the field is required but has no raw.
  if (required && !raw) {
    field.meta.override = true
  }

  // Set media list form elements to an empty array if they don't have any
  // value yet. This makes Vue's checkbox v-model array binding possible.
  if (type === 'media-list' && !additional) {
    field.additional = []
  }

  // Set multi option form elements to an empty array if they don't have any
  // value yet. This makes Vue's checkbox v-model array binding possible.
  if (type === 'multi-option' && !additional) {
    field.additional = []
  }

  return field
}

/**
 * Get analysis data
 * @param {string} id Analysis ID
 */
export async function get (id, lang) {
  const response = await connector.withLang(lang).get(`laboreditor/edit/${id}`).json()
  response.form_fields = response.form_fields.map(preprocessFields)

  // Force override for standalone analyses. This cirumvents edge cases with
  // imported analyses that have been converted to standalone ones.
  if (response.is_standalone) {
    response.form_fields.forEach(field => { field.meta.override = true })
  }

  return response
}

/**
 * Get form for new analyses
 * @param {number} [category] Form category
 */
export async function getForm (categories) {
  const searchParams = {}

  if (categories) {
    searchParams.categories = categories
  }

  const response = await connector.get('laboreditor/create', { searchParams }).json()
  response.form_fields = response.form_fields.map(preprocessFields)
  return response
}

/**
 * Create new analysis
 * @param {Object} data Analysis data
 */
export function create (data) {
  return connector.post('laboreditor/create', data).json()
}

/**
 * Update existing analysis
 * @param {string} id Analysis ID
 * @param {Object} data Analysis data
 */
export function update (id, data, lang) {
  return connector.withLang(lang).patch(`laboreditor/edit/${id}`, data).json()
}

/**
 * Remove existing analysis
 * @param {Object} options
 * @param {string} options.id Analysis ID
 */
export function remove ({ id }) {
  return connector.delete(`laboreditor/edit/${id}`)
}
