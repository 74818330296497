import showMaintenance from '../../../functions/showMaintenance.js'
import connector from '../../connector.js'

/**
 * Get LaborPublisher maintenance status
 * @returns {Promise<boolean>} Maintenance mode active
 */
export async function get () {
  /** @type {boolean} */
  const isMaintenance = await connector.get('maintenance').json()

  if (isMaintenance) {
    showMaintenance()
  }

  return isMaintenance
}
