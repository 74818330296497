/**
 * All settings are exported individually for better tree-shaking.
 * Most of these values benefit from inlining during compilation/minification.
 */

export const BASE_URL = import.meta.env.BASE_URL

/** Differentiate keyboard layouts. */
export const PLATFORM = navigator.platform.match('Mac') ? 'mac' : 'pc'

/** localStorage key for LaborEditor settings. */
export const LOCALSTORAGE_KEY_LABOREDITOR = 'lp-laboreditor'

/** Check LaborPublisher version after elapsed time in milliseconds. */
export const VERSION_CHECK_FREQUENCY = 5 * 60 * 1000

/** Loader display time until revert in milliseconds. */
export const LOADER_REVERT_TIMEOUT = 4000

/** Default notification timeout in milliseconds. */
export const NOTIFICATION_TIMEOUT = 4000

/** Number of items per list page. */
export const GLOBAL_LIST_COUNT = 20

/** Number of LaborEditor analyses per page. */
export const LABOREDITOR_LIST_COUNT = 100

/** Number of Ringversuche items per page. */
export const PROFICIENCY_LIST_COUNT = 100

/** Number of LaborShop items per page. */
export const LABORSHOP_LIST_COUNT = 100

/** Number of Collection items per page */
export const COLLECTION_LIST_ITEM_COUNT = 100

/** HTML template files for PrintPublisher. */
export const PRINTPUBLISHER_HTML_FILES = [
  'template',
  'includes/chapter',
  'includes/cover',
  'includes/directory',
  'includes/directory_two_columns',
  'includes/directory_field',
  'includes/directory_field_category',
  'includes/directory_field_column',
  'includes/directory_field_generic',
  'includes/directory_field_list',
  'includes/directory_field_section',
  'includes/directory_field_table',
  'includes/index',
  'includes/paragraph',
  'includes/rte',
  'includes/title',
  'includes/toc'
]

/** CSS files for PrintPublisher. */
export const PRINTPUBLISHER_CSS_FILES = [
  'main',
  'template_digital',
  'template_print',
  'css/chapter',
  'css/cover',
  'css/directory',
  'css/directory-field',
  'css/directory-field-generic',
  'css/directory-field-list',
  'css/directory-field-section',
  'css/directory-field-table',
  'css/index',
  'css/page',
  'css/paragraph',
  'css/rte',
  'css/scaffolding',
  'css/title',
  'css/toc',
  'css/typography',
  'css/utils'
]
