import { PROFICIENCY_LIST_COUNT } from '../../../settings.js'
import connector from '../../connector.js'

/**
 * Get certificate
 * @param {Object} options
 * @param {number} options.id Certificate ID
 */
export function get ({ id }) {
  return connector.get(`proficiency/certificates/${id}`).json()
}

/**
 * Get certificate list
 * @param {Object} options Options
 * @param {string} options.search Search term
 * @param {number} options.offset Offset value
 * @param {number} options.limit Number of results
 */
export function getList ({ search, offset, limit = PROFICIENCY_LIST_COUNT }) {
  const searchParams = { limit, search, offset }
  return connector.get('proficiency/certificates', { searchParams }).json()
}

/**
 * Create new certificate
 * @param {Object} options
 * @param {Object} options.data Certificate data
 */
export function create ({ data }) {
  return connector.post('proficiency/certificates', data).json()
}

/**
 * Update existing certificate
 * @param {Object} options
 * @param {number} options.id Certificate ID
 * @param {Object} options.data Certificate data
 */
export function update ({ id, data }) {
  return connector.patch(`proficiency/certificates/${id}`, data).json()
}

/**
 * Remove certificate
 * @param {Object} options
 * @param {number} options.id Certificate ID
 */
export function remove ({ id }) {
  return connector.delete(`proficiency/certificates/${id}`).json()
}

export function preflight (id) {
  return connector.get(`proficiency/certificates/${id}/delete-preflight`).json()
}
