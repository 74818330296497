<script setup>
import { createFocusTrap } from 'focus-trap'
import { nanoid } from 'nanoid'
import { nextTick, onMounted, ref, watch } from 'vue'
import { GlobalEvents } from 'vue-global-events'

// Components
import CButton from './CButton.vue'
import CTitle from './CTitle.vue'

const props = defineProps({
  open: {
    type: Boolean
  },
  size: {
    type: String,
    required: true,
    validator: value => [
      'small',
      'large'
    ].includes(value)
  },
  closable: {
    type: Boolean
  },
  title: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['close', 'closed'])

const titleID = nanoid()
const focusTrap = ref(null)
const dialogRef = ref(null)

function activate() {
  document.body.style.overflowY = 'hidden'
  focusTrap.value.activate()
}

function deactivate() {
  document.body.style.overflowY = ''
  focusTrap.value.deactivate()
}

function onClose() {
  emit('close')
}

watch(() => props.open, async isOpen => {
  if (isOpen) {
    await nextTick()
    activate()
  }
})

onMounted(() => {
  focusTrap.value = createFocusTrap(dialogRef.value, {
    escapeDeactivates: false,
    fallbackFocus: dialogRef.value,
    onDeactivate() {
      emit('closed')
    }
  })
})
</script>

<template>
  <transition
    name="fade"
    @after-leave="deactivate"
  >
    <div
      v-show="open"
      class="overlay"
    >
      <GlobalEvents
        v-if="open && closable"
        @keydown.esc="onClose"
      />

      <section
        ref="dialogRef"
        class="overlay__dialog ma-5 bg-white br-2 bs-large"
        :class="`is-size-${size}`"
        aria-modal="true"
        :aria-labelledby="titleID"
        role="dialog"
        tabindex="-1"
      >
        <header class="overlay__header flex items-center px-5 py-4">
          <CTitle
            :id="titleID"
            level="2"
          >
            {{ title }}
          </CTitle>

          <CButton
            v-if="closable"
            aria-label="Schließen"
            icon="close"
            size="small"
            color="ghost"
            class="ml-auto"
            @click="onClose"
          />
        </header>

        <div class="pa-5">
          <slot />
        </div>
      </section>
    </div>
  </transition>
</template>

<style lang="scss">
@use "../css/variables";

.overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: variables.$z-index-overlay;

  background: rgb(0 0 0 / 0.75);

  @media (min-width: 40rem) {
    align-items: center;
  }
}

.overlay__dialog {
  &.is-size-small {
    flex-basis: 30em;
  }

  &.is-size-large {
    flex-basis: 60em;
  }

  @media (prefers-reduced-motion: no-preference) {

    .fade-enter-active &,
    .fade-leave-active & {
      transition: transform 0.4s;
    }

    .fade-enter-active & {
      transition-timing-function: cubic-bezier(0.33, 0.22, 0.35, 1.74);
    }

    .fade-enter-from &,
    .fade-leave-to & {
      transform: scale(0.9);
    }
  }
}

.overlay__header {
  border-bottom: 0.0625em solid var(--color-gray-300);
}
</style>
