import store from '../../../store'
import connector from '../../connector.js'

/**
 * Get single category
 * @param {number} id Category ID
 * @returns {Promise<import('../../../types').ShopCategory>}
 */
export async function get (id) {
  const response = await connector.get(`laborshop/categories/${id}`).json()
  store.commit('laborShop/addCategory', response)
  return response
}

/**
 * Get category list
 * @returns {Promise<import('../../../types').ShopCategory[]>}
 */
export async function getList () {
  const response = await connector.get('laborshop/categories').json()
  store.dispatch('laborShop/addCategoryList', response)
  return response
}

/**
 * Create new category
 * @param {import('../../../types').ShopCategory} data Category data
 * @returns {Promise<import('../../../types').ShopCategory>}
 */
export async function create (data) {
  const response = await connector.post('laborshop/categories', data).json()
  store.commit('laborShop/addCategory', response)
  return response
}

/**
 * Update existing category
 * @param {number} id Category ID
 * @param {import('../../../types').ShopCategory} data Category data
 * @returns {Promise<import('../../../types').ShopCategory>}
 */
export async function update (id, data) {
  const response = await connector.patch(`laborshop/categories/${id}`, data).json()
  store.commit('laborShop/addCategory', response)
  return response
}

/**
 * Set category sorting
 * @param {{id: number, sort_index: number}[]} data Category sorting data
 */
export function sort (data) {
  return connector.post('laborshop/categories/sorting', data)
}

/**
 * Remove category
 * @param {Object} options
 * @param {number} options.id Category ID
 */
export async function remove ({ id }) {
  const response = await connector.delete(`laborshop/categories/${id}`)
  store.commit('laborShop/removeCategory', id)
  return response
}
