<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

// Functions
import getSidebarFromRoute from '../functions/getSidebarFromRoute.js'

const store = useStore()
const route = useRoute()

const isExpanded = computed(() => store.state.sidebarExpanded)

function getSidebar (type) {
  const component = getSidebarFromRoute(route, type)

  if (component) {
    return defineAsyncComponent(() => component)
  }

  return false
}

const menuSidebar = computed(() => getSidebar('menu'))
const contentSidebar = computed(() => getSidebar('content'))
</script>

<template>
  <aside
    class="sidebar bg-white"
    :class="{
      'is-expanded': isExpanded
    }"
  >
    <div
      v-if="menuSidebar"
      class="sidebar__menu bg-white bs-medium"
    >
      <component :is="menuSidebar" />
    </div>

    <div
      v-if="contentSidebar"
      class="sidebar__content bg-white bs-medium"
    >
      <component :is="contentSidebar" />
    </div>
  </aside>
</template>

<style lang="scss">
@use "../css/variables";

.sidebar {
  position: fixed;
  top: variables.$header-height;
  display: flex;
  flex-shrink: 0;
  max-width: 100vw;
  height: calc(100vh - #{variables.$header-height});
  z-index: variables.$z-index-sidebar;

  visibility: hidden;

  &.is-expanded {
    transform: translateX(0);
    visibility: visible;
  }

  @media (prefers-reduced-motion: no-preference) {
    transform: translateX(-100%);

    transition:
      transform 0.3s,
      visibility 0.3s;

    &.is-expanded {
      transform: translateX(0);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    opacity: 0;

    transition:
      opacity 0.2s,
      visibility 0.2s;

    &.is-expanded {
      opacity: 1;
    }
  }

  @media (min-width: variables.$breakpoint-sidebar) {
    // Make 'em sticky so on scroll the sidebar stays in place.
    position: sticky;

    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }
}

.sidebar__menu {
  position: relative;
  z-index: 2;
}

.sidebar__content {
  position: relative;
  width: 22em;
  max-width: 100%;
  z-index: 1;
  overflow: hidden auto;
  overscroll-behavior-y: contain;
}
</style>
