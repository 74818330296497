/**
 * Welcome to the LaborPublisher Admin frontend code.
 * These comments will guide you through the code base.
 */

/* eslint-disable simple-import-sort/imports */
import { createApp } from 'vue'
import i18n from './i18n.js'

// Entry
import App from './App.vue'

// Plugins
import router from './router'
import store from './store'

// Functions
import api from './api'
import attachSentry from '@laborpublisher/global/js/attachSentryVue.js'
import showNoConnection from './functions/showNoConnection.js'
import token from './functions/token.js'

// Globals
import { APP_ENVIRONMENT } from '@laborpublisher/global/config/settings.js'
import enums from './enums.js'

// Locales (Languages)
import de from './locales/de.json'

// CSS
import './main.scss'
/* eslint-enable simple-import-sort/imports */

store.commit('dataState', enums.status.LOADING)

// Get initial data before Vue is even initialized.
// This ensures every necessary data is available before routing begins.
const initialDataRequests = [
  api.settings.system.getPublic(),
  api.global.maintenance.get()
]

// If a token is present, we can try to get current user data and the booked
// modules so nobody has to wait for this once Vue finishes rendering.
if (token.load()) {
  initialDataRequests.push(api.settings.system.getBookedModules())
  initialDataRequests.push(api.settings.user.getCurrent())
}

Promise.all(initialDataRequests)
  .then(() => {
    store.commit('dataState', enums.status.SUCCESS)
  })
  .catch(error => {
    if (error.response.status === 404) {
      store.commit('dataState', enums.status.INVALID_TENANT)
    } else {
      store.commit('dataState', enums.status.ERROR)
    }
  })
  // Set up Vue once all initial requests are done.
  .finally(() => {
    // Handle preload errors that occur when users with opened pages
    // try to load old cached files that were deleted by a deployment.
    // In this case, just force a reload.
    window.addEventListener("vite:preloadError", (event) => {
      window.location.reload()
    })

    const app = createApp(App)

    if (APP_ENVIRONMENT !== 'local') {
      attachSentry({ app, dsn: 'https://fe0edbaef5634fba9f01bf9487e6d27a@sentry.lfda.de/3' })
    }

    app.use(router)
    app.use(store)
    app.use(i18n)
    app.mount('body')

    if (store.state.dataState === enums.status.ERROR) {
      showNoConnection()
    }

    if (store.state.dataState === enums.status.INVALID_TENANT) {
      router.push({ name: 'login-invalid-tenant' })
    }
  })
