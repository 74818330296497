// Functions
// Globals
import enums from '../../enums.js'
import userCan from '../../functions/userCan.js'

/**
 * @type {import("vue-router").RouteConfig[]}
 */
export default [
  {
    path: '',
    name: 'proficiency',
    component: () => import('../../views/VProficiencyIntro.vue')
  },
  {
    path: 'einrichtung',
    name: 'proficiency-setup',
    component: () => import('../../views/VProficiencySetup.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'change_configuration'),
      title: 'Einrichtung'
    }
  },
  {
    path: 'texte',
    name: 'proficiency-language',
    component: () => import('../../views/VProficiencyLanguage.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'change_configuration'),
      title: 'Texte'
    }
  },
  {
    path: 'zertifikate',
    component: () => import('../../views/VProficiencyCertificate.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_certificate'),
      title: 'Zertifikate',
      sidebar: {
        content: import('../../components/CProficiencySidebarCertificate.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-certificate',
        component: () => import('../../views/VProficiencyCertificateIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-certificate-create',
        component: () => import('../../views/VProficiencyCertificateEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_certificate'),
          title: 'Neues Zertifikat'
        }
      },
      {
        path: ':id',
        name: 'proficiency-certificate-edit',
        component: () => import('../../views/VProficiencyCertificateEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Zertifikat bearbeiten'
        }
      }
    ]
  },
  {
    path: 'zertifizierungen',
    component: () => import('../../views/VProficiencyCertification.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_certificate'),
      title: 'Neue Zertifizierung'
    },
    children: [
      {
        path: 'neu',
        name: 'proficiency-certification',
        component: () => import('../../views/VProficiencyCertificationCreate.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_certificate'),
          title: 'Neue Zertifizierung'
        }
      },
      {
        path: 'testparameter',
        component: () => import('../../views/VProficiencyCertifiedTestable.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'view_certificate'),
          title: 'Zertifizierte Testparameter',
          sidebar: {
            content: import('../../components/CProficiencySidebarCertifiedTestable.vue')
          }
        },
        children: [
          {
            path: '',
            name: 'proficiency-certifiedTestable',
            component: () => import('../../views/VProficiencyCertifiedTestableIntro.vue')
          },
          {
            path: 'neu',
            name: 'proficiency-certifiedTestable-create',
            component: () => import('../../views/VProficiencyCertifiedTestableEdit.vue'),
            meta: {
              permission: () => userCan(enums.permissions.PROFICIENCY, 'add_certificate'),
              title: 'Einzelnen Testparameter zertifizieren'
            }
          },
          {
            path: ':id',
            name: 'proficiency-certifiedTestable-edit',
            component: () => import('../../views/VProficiencyCertifiedTestableEdit.vue'),
            props: route => ({
              id: parseInt(route.params.id)
            }),
            meta: {
              title: 'Bestehende Zeritifizierung bearbeiten'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'parameter',
    component: () => import('../../views/VProficiencyParameter.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_certificate'),
      title: 'Parameter',
      sidebar: {
        content: import('../../components/CProficiencySidebarParameter.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-parameter',
        component: () => import('../../views/VProficiencyParameterIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-parameter-create',
        component: () => import('../../views/VProficiencyParameterEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_certificate'),
          title: 'Neuer Parameter'
        }
      },
      {
        path: ':id',
        name: 'proficiency-parameter-edit',
        component: () => import('../../views/VProficiencyParameterEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Parameter bearbeiten'
        }
      }
    ]
  },
  {
    path: 'testparameter',
    component: () => import('../../views/VProficiencyTestable.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_testable'),
      title: 'Testparameter',
      sidebar: {
        content: import('../../components/CProficiencySidebarTestable.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-testable',
        component: () => import('../../views/VProficiencyTestableIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-testable-create',
        component: () => import('../../views/VProficiencyTestableEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_testable'),
          title: 'Neuer Testparameter'
        }
      },
      {
        path: ':id',
        name: 'proficiency-testable-edit',
        component: () => import('../../views/VProficiencyTestableEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_testable'),
          title: 'Testparameter bearbeiten'
        }
      }
    ]
  },
  {
    path: 'material',
    component: () => import('../../views/VProficiencyMaterial.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_certificate'),
      title: 'Material',
      sidebar: {
        content: import('../../components/CProficiencySidebarMaterial.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-material',
        component: () => import('../../views/VProficiencyMaterialIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-material-create',
        component: () => import('../../views/VProficiencyMaterialEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_material'),
          title: 'Neues Material'
        }
      },
      {
        path: ':id',
        name: 'proficiency-material-edit',
        component: () => import('../../views/VProficiencyMaterialEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Material bearbeiten'
        }
      }
    ]
  },
  {
    path: 'kategorien',
    component: () => import('../../views/VProficiencyCategory.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_category'),
      title: 'Kategorien',
      sidebar: {
        content: import('../../components/CProficiencySidebarCategory.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-category',
        component: () => import('../../views/VProficiencyCategoryIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-category-create',
        component: () => import('../../views/VProficiencyCategoryEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_tag'),
          title: 'Neue Kategorie'
        }
      },
      {
        path: ':id',
        name: 'proficiency-category-edit',
        component: () => import('../../views/VProficiencyCategoryEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Kategorie bearbeiten'
        }
      }
    ]
  },
  {
    path: 'tags',
    component: () => import('../../views/VProficiencyTag.vue'),
    meta: {
      permission: () => userCan(enums.permissions.PROFICIENCY, 'view_tag'),
      title: 'Tags',
      sidebar: {
        content: import('../../components/CProficiencySidebarTag.vue')
      }
    },
    children: [
      {
        path: '',
        name: 'proficiency-tag',
        component: () => import('../../views/VProficiencyTagIntro.vue')
      },
      {
        path: 'neu',
        name: 'proficiency-tag-create',
        component: () => import('../../views/VProficiencyTagEdit.vue'),
        meta: {
          permission: () => userCan(enums.permissions.PROFICIENCY, 'add_tag'),
          title: 'Neuer Tag'
        }
      },
      {
        path: ':id',
        name: 'proficiency-tag-edit',
        component: () => import('../../views/VProficiencyTagEdit.vue'),
        props: route => ({
          id: parseInt(route.params.id)
        }),
        meta: {
          title: 'Tag bearbeiten'
        }
      }
    ]
  },
  {
    path: 'vorschau',
    name: 'proficiency-preview',
    component: () => import('../../views/VProficiencyPreview.vue'),
    meta: {
      title: 'Vorschau'
    }
  }
]
