import i18n from '../i18n'
import store from '../store'

const t = i18n.global.t

/**
 * Notify about "broken" connection to backend.
 */
export default function () {
  store.commit('openModal', {
    title: t('globals.modals.noConnection.title'),
    content: t('globals.modals.noConnection.description'),
    buttons: [{
      text: t('globals.modals.noConnection.retryConnection'),
      color: 'light',
      handler: () => location.reload()
    }]
  })
}
