import store from '../store'

/**
 * Check if user is allowed to perform an action
 * @param {string} scope Permission scope (e.g. module)
 * @param {string} action Action to perform
 * @returns {boolean} User can do this
 */
export default function (scope, action) {
  return store.state.user.current.permissions?.includes(`${scope}.${action}`)
}
