/**
 * Because we have an immense amount of routes, the router is split into lots of
 * smaller "sub-routes" that inherit some properties (like sidebars) from their parent.
 */

import { createRouter, createWebHistory } from 'vue-router'

import afterEach from './afterEach.js'
import beforeEach from './beforeEach.js'
import routes from './routes'
import scrollBehavior from './scrollBehavior.js'

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
  routes,
  scrollBehavior
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
