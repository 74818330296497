/**
 * This module cannot be named "import" because "import" cannot be imported!
 */

import connector from '../../connector.js'

/**
 * Upload import
 * @param {string} data Data to upload
 * @param {Object} options Request options
 */
export function upload (data, options) {
  return connector.post('upload/analysis/file', data, options).json()
}

/**
 * Get auth token for import user
 */
export function getToken () {
  return connector.get('auth/import-token/acquire').json()
}

/**
 * Revoke all auth tokens for import user
 */
export function revokeTokens () {
  return connector.get('auth/import-token/nuke-from-orbit').json()
}

/**
 * Get log for last import
 */
export function getLog () {
  return connector.get('import/last').json()
}
