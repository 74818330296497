import connector from '../../connector.js'

/**
 * Start PDF generation
 * @param {Object} data Generate options
 * @param {boolean} [data.test_dataset] Use reduced test dataset
 */
export function generate (data) {
  return connector.post('printpublisher/generate', data).json()
}

/**
 * Get PrintPublisher preview
 */
export function preview () {
  return connector.get('printpublisher/preview').json()
}

/**
 * Publish generated PDFs
 */
export function publish () {
  return connector.get('printpublisher/publish').json()
}

/**
 * Get PrintPublisher download
 */
export function download () {
  return connector.get('printpublisher/download').json()
}
