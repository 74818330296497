// Globals
import api from '../../api'
import enums from '../../enums.js'
import { LOCALSTORAGE_KEY_LABOREDITOR } from '../../settings.js'

export const namespaced = true

export const state = {
  list: {
    hasNext: false,
    headers: [],
    items: []
  },
  listCategories: [],
  listState: enums.status.IDLE,
  listFilter: {
    offset: 0,
    search: '',
    pubstate: '',
    category: '',
    internal: false,
    ...JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY_LABOREDITOR))
  }
}

/**
 * @type {import("vuex").MutationTree<state>}
 */
export const mutations = {
  /**
   * Set analysis list
   * @param {Object} data Analysis list data
   * @param {boolean} data.has_next Has more items after offset
   * @param {string[]} data.headers Value headers
   * @param {{values: string[], identifier: string}[]} data.items Analysis list
   */
  list (state, data) {
    state.list.hasNext = data.has_next
    state.list.headers = data.headers
    state.list.items = data.items
  },

  /**
   * Set list categories
   * @param {number} data List categories
   */
  listCategories (state, data) {
    state.listCategories = data
  },

  /**
   * Set list state
   * @param {number} status List state
   */
  listState (state, status) {
    state.listState = status
  },

  /**
   * Set list filter
   * @param {Object} filter Filter settings
   */
  listFilter (state, filter) {
    // Only update filter settings that are committed and keep all the other ones.
    for (const item in filter) {
      state.listFilter[item] = filter[item]
    }

    localStorage.setItem(LOCALSTORAGE_KEY_LABOREDITOR, JSON.stringify(state.listFilter))
  }
}

/**
 * @type {import("vuex").ActionTree<state>}
 */
export const actions = {
  async updateList (context, filter) {
    const oldFilter = { ...context.state.listFilter }
    context.commit('listState', enums.status.LOADING)
    context.commit('listFilter', filter)

    try {
      await api.laborEditor.list.get(context.state.listFilter)
      await api.laborEditor.category.getListWithCommit()
      context.commit('listState', enums.status.SUCCESS)
    } catch {
      // Roll back filter on unsuccessful list updte.
      context.commit('listFilter', oldFilter)
      context.commit('listState', enums.status.ERROR)
    }
  }
}
