import i18n from '../i18n'
import store from '../store'

const t = i18n.global.t

/**
 * Notify about new LaborPublisher version.
 * @param {string} version New version number
 */
export default function (version) {
  store.commit('openModal', {
    closable: false,
    title: t('globals.modals.newVersion.title'),
    content: t('globals.modals.newVersion.description', { version }),
    buttons: [{
      text: t('globals.modals.newVersion.activateUpdate'),
      color: 'light',
      handler: () => location.reload()
    }]
  })
}
